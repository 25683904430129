import { useState } from "react";
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import { Wine } from "../interfaces";
import { backgroundColor } from "../static/index";
import { wineStyles, countryList } from "../static/index";
import MessagePopup from "./MessagePopup";

function AddWine() {
const initFormData = {
    _id: null,
    code: "",
    vintage: "",
    style: "",
    specification: "",
    country: "",
    ml: "",
    msrp: 0,
    inventory: 0,
    appellation: null,
    sub_appellation: null,
    wine_by_glass_ff: false,
    wine_by_glass_win: false,
    natural: null,
    archived: false,
    sommeliers: false,
}

const [formData, setFormData] = useState<Wine>(initFormData);
const [showError, setShowError] = useState<boolean>(false);
const [errorMessage, setErrorMessage] = useState<string>("");

const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;


    // if (name === "vintage" && value.length > 0) {
    //     const regex = /^[0-9]{0,4}$/;
    //     if (!regex.test(value)) {
    //     // Invalid vintage format, handle the error (e.g., display an error message)
    //     return;
    //     }
    // }

    if ((name === "msrp" || name === "inventory") && Number(value) < 0) {
        // Invalid input, handle the error (e.g., display an error message)
        return;
    }
    

    setFormData((prevFormData: any) => ({
    ...prevFormData,
    [name]: value,
    }));
};


const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    axiosCredentialsInstance.post("/wine", formData).then((res) => {
        setFormData(initFormData);
        }).catch((err) => {
            setShowError(true);
            setErrorMessage(err.response.data);
            setTimeout(() => {
                setShowError(false);
                setErrorMessage("");
            }, 2000);
        console.log(err);
    });
};

return (
    <div className="w-11/12 h-4/5 my-auto rounded-xl p-6" style={{ backgroundColor: backgroundColor}}>
    <MessagePopup show={showError} messagePopup={errorMessage} />
    <form className="flex flex-wrap" onSubmit={handleSubmit}>
        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="code" className="block text-sm font-medium text-gray-700">
        產品編號
        </label>
        <input
            type="text"
            name="code"
            id="code"
            value={formData.code}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            required
        />
        </div>
        <div className="w-1/5 my-6 pr-4">
            <label htmlFor="vintage" className="block text-sm font-medium text-gray-700">
                Vintage
            </label>
            <input
                type="text"
                name="vintage"
                id="vintage"
                value={formData.vintage}
                onChange={handleChange}
                className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                required
            />
        </div>

        <div className="w-1/5 my-6 pr-4">
            <label htmlFor="style" className="block text-sm font-medium text-gray-700">
                Style
            </label>
            <select
                name="style"
                id="style"
                value={formData.style}
                onChange={handleChange}
                className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                required
            >
                <option value="">-- Select Style --</option>
                {wineStyles.map((style) => (
                <option key={style} value={style}>
                    {style}
                </option>
                ))}
            </select>
        </div>

        <div className="w-2/5 my-6 pr-4">
        <label htmlFor="specification" className="block text-sm font-medium text-gray-700">
            品名規格
        </label>
        <input
            type="text"
            name="specification"
            id="specification"
            value={formData.specification}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            required
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country
            </label>
            <select
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
                className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
                required
            >
                <option value="">Select a country</option>
                {countryList.map((country) => (
                <option key={country} value={country}>
                    {country}
                </option>
                ))}
            </select>
        </div>


        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="ml" className="block text-sm font-medium text-gray-700">
            ML
        </label>
        <input
            type="text"
            name="ml"
            id="ml"
            value={formData.ml}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            required
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="msrp" className="block text-sm font-medium text-gray-700">
            建議售價
        </label>
        <input
            type="number"
            name="msrp"
            id="msrp"
            value={formData.msrp}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            required
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="inventory" className="block text-sm font-medium text-gray-700">
            庫存量
        </label>
        <input
            type="number"
            name="inventory"
            id="inventory"
            value={formData.inventory}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            required
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="appellation" className="block text-sm font-medium text-gray-700">
            產區
        </label>
        <input
            type="text"
            name="appellation"
            id="appellation"
            value={formData.appellation || ""}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="sub_appellation" className="block text-sm font-medium text-gray-700">
            小產區
        </label>
        <input
            type="text"
            name="sub_appellation"
            id="sub_appellation"
            value={formData.sub_appellation || ""}
            onChange={handleChange}
            className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
        />
        </div>
        
        <div className="w-1/5 my-6 pr-4">
            <label htmlFor="natural" className="block text-sm font-medium text-gray-700">
                Natural
            </label>
            <select
                name="natural"
                id="natural"
                value={formData.natural || ""}
                onChange={handleChange}
                className="mt-1 bg-white px-5 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md h-10"
            >
                <option value=""></option>
                <option value="Natural">Natural</option>
            </select>
        </div>


        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="wine_by_glass_ff" className="block text-sm font-medium text-gray-700">
            Wine by Glass (FF)
        </label>
        <input
            type="checkbox"
            name="wine_by_glass_ff"
            id="wine_by_glass_ff"
            checked={formData.wine_by_glass_ff}
            onClick={() => {
                setFormData({
                ...formData,
                wine_by_glass_ff: !formData.wine_by_glass_ff
                })
            }}
            className="mt-1"
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="wine_by_glass_win" className="block text-sm font-medium text-gray-700">
            Wine by Glass (Win)
        </label>
        <input
            type="checkbox"
            name="wine_by_glass_win"
            id="wine_by_glass_win"
            checked={formData.wine_by_glass_win}
            onClick={() => {
                setFormData({
                ...formData,
                wine_by_glass_win: !formData.wine_by_glass_win
                })
            }}
            className="mt-1"
        />
        </div>



        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="archived" className="block text-sm font-medium text-gray-700">
            Archived
        </label>
        <input
            type="checkbox"
            name="archived"
            id="archived"
            checked={formData.archived}
            onClick={() => {
                setFormData({
                ...formData,
                archived: !formData.archived
                })
            }}
            className="mt-1"
        />
        </div>

        <div className="w-1/5 my-6 pr-4">
        <label htmlFor="sommeliers" className="block text-sm font-medium text-gray-700">
            sommeliers
        </label>
        <input
            type="checkbox"
            name="sommeliers"
            id="sommeliers"
            checked={formData.sommeliers}
            onClick={() => {
                setFormData({
                ...formData,
                sommeliers: !formData.sommeliers
                })
            }}
            className="mt-1"
        />
        </div>


        <div className="w-full my-4 ">
        <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            Add Wine
        </button>
        </div>
    </form>
    </div>
);
}

export default AddWine;
