import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Wine } from '../interfaces';
import { AgGridReact } from 'ag-grid-react'
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import '../App.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../static/styles.css';
import PopUp from '../components/PopUp';
import AddWine from '../components/AddWine';
import { wineStyles, countryList } from "../static/index";
import "../static/styles.css";

function WineGrid(props: any) {
	const [show, setShow] = useState(false);
	const [pinnedBottomRowData, setPinnedBottomRowData] = useState<any>(false);
	const [wineData, setWineData] = useState<Wine[]>([]);
	const [reloadFlag, setReloadFlag] = useState(false);
	const [editing, setEditing] = useState(true);
	const [showDelete, setShowDelete] = useState(false);
	const gridRef = useRef<AgGridReact<Wine>>(null);

	const getWine = async () => {
		const wine = await axiosCredentialsInstance.get('/wine?$filter=wine-by-glass eq null');
		return wine.data
	}

	const handleCheckboxChange = (id: string) => {
		return (e: any) => {
			axiosCredentialsInstance.put(`/wine/${id}/switch-archived`)
		}
	}

	const SelectCellRenderer = (props: any) => {
		const value = props.value || '';

		const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
			const newValue = event.target.value;
			props.node.setDataValue(props.colDef.field, newValue);
		};

		return (
			<select value={value} onChange={handleChange} className="bg-transparent">
				<option value=""></option>
				<option value="Natural">Natural</option>
			</select>
		);
	};

	const CountryCellRenderer = (props: any) => {
		const value = props.value || '';
		const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
			const newValue = event.target.value;
			props.node.setDataValue(props.colDef.field, newValue);
		};


		return (
			<select
				value={value}
				onChange={handleChange}
				className="bg-transparent"
			>
				{props.data.style !== 'Red' && props.data.style !== 'White' && <option key={0} value=""></option>}
				{countryList.map((country, index) => (
					<option key={index} value={country}>
						{country}
					</option>
				))}
			</select>
		);
	};

	const StyleCellRenderer = (props: any) => {

		const value = props.value || '';

		const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
			const newValue = event.target.value;
			props.node.setDataValue(props.colDef.field, newValue);
		};


		return (
			<select
				value={value}
				onChange={handleChange}
				className="bg-transparent"
			>
				{wineStyles.map((style, index) => (
					<option key={index} value={style}>
						{style}
					</option>
				))}
			</select>
		);
	};


	const archivedComp = (p: any) => {
		return (
			<label className="inline-flex items-center">
				<input
					type="checkbox"
					className="form-checkbox text-indigo-600 h-5 w-5"
					defaultChecked={p.value}
					onChange={handleCheckboxChange(p.data._id)}
				/>
				{/* <span className='ml-2'>
                  {p.data.inventory === 0 ? "Unavailable" : "Available"}
                </span> */}
			</label>
		)
	}

	const sommeliersComp = (p: any) => {
		return (
			<div className="check-div background-color">
				<label>
					<input type="checkbox" defaultChecked={p.value} onChange={() => {
						axiosCredentialsInstance.put(`/wine/${p.data._id}/switch-sommeliers`)
					}} /> <span>Sommeliers</span>
				</label>
			</div>
		)
	}

	const wineByGlassFFComp = (p: any) => {
		return (
			<div className="check-div background-color">
				<label>
					<input type="checkbox" defaultChecked={p.value} onChange={() => {
						axiosCredentialsInstance.put(`/wine/${p.data._id}/switch-wine-by-glass-ff`)
					}} /><span>FReNCHIE</span>
				</label>
			</div>
		);
	}

	const wineByGlassWINComp = (p: any) => {
		return (
			<div className="check-div background-color">
				<label>
					<input type="checkbox" defaultChecked={p.value} onChange={() => {
						axiosCredentialsInstance.put(`/wine/${p.data._id}/switch-wine-by-glass-win`)
					}} /><span>Win</span>
				</label>
			</div>
		);
	};

	const [columnDefs, setColumnDefs] = useState<any>(
		[
			{ field: "code", headerName: "產品編號", width: 120 },
			{ field: "vintage", headerName: "Vintage", width: 120 },
			{ field: "style", headerName: "Style", width: 120, editable: false, cellRenderer: StyleCellRenderer },
			{ field: "specification", headerName: "品名規格", width: 500 },
			{ field: "country", headerName: "國家", width: 120, editable: false, cellRenderer: CountryCellRenderer },
			{ field: "appellation", headerName: "產區", width: 150 },
			{ field: "sub_appellation", headerName: "小產區", width: 150 },
			{ field: "msrp", headerName: "建議售價", width: 120 },
			{ field: "inventory", headerName: "庫存量", width: 120 },
			{ field: "ml", headerName: "ML", width: 120 },
			{ field: "natural", headerName: "Natural", width: 120, editable: false, cellRenderer: SelectCellRenderer },
			{ field: "wine_by_glass_ff", headerName: "FF單杯", width: 120, editable: false, filter: false, floatingFilter: false, cellRenderer: wineByGlassFFComp, pinned: 'right' },
			{ field: "wine_by_glass_win", headerName: "WIN單杯", width: 120, editable: false, filter: false, floatingFilter: false, cellRenderer: wineByGlassWINComp, pinned: 'right' },
			{ field: "sommeliers", headerName: "Sommeliers", width: 120, editable: false, filter: false, floatingFilter: false, cellRenderer: sommeliersComp, pinned: 'right' },
			{ field: "archived", headerName: "典藏", editable: false, filter: false, floatingFilter: false, width: 100, cellRenderer: archivedComp, pinned: 'right' },

		]
	);

	const defaultColDef = useMemo(() => ({
		editable: editing,
		filter: 'agTextColumnFilter',
		sortable: true,
		resizable: true,
		floatingFilter: true,

	}), []);

	const cellClikedListener = useCallback((event: any) => {
		axiosCredentialsInstance.put('/wine/' + event.data._id, event.data).then((res) => {
			console.log(res)
		}).catch((err) => {
			console.log(err)
		})
	}, []);

	useEffect(() => {
		const setData = async () => {
			const wineData = await getWine();
			console.log(wineData)
			setWineData(wineData);
		}
		setData();
	}, [reloadFlag])


	return (
		<>
			<div>
				<div className='ag-theme-alpine w-full' style={{ height: '96vh' }}>
					<AgGridReact
						ref={gridRef}
						onCellValueChanged={cellClikedListener}
						rowData={wineData}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
						rowSelection='multiple'
						animateRows={true}
						pinnedBottomRowData={pinnedBottomRowData}
					/>
				</div>
				<nav className='mx-auto z-50 bg-white flex  shadow-md non-print-section' style={{ height: "4vh" }}>
					<div className="flex justify-end items-center w-full px-10">
						<div className='rounded-xl border-black border-2 px-2 mx-2 cursor-pointer' onClick={() => {
							setShow(true)
						}}>Add Wine</div>

						<div className='rounded-xl border-black border-2 px-2 mx-2 cursor-pointer' onClick={() => {
							setShowDelete(true)
						}}>Delete selected</div>

						<div className='rounded-xl border-black border-2 px-2 mx-2 cursor-pointer' onClick={() =>
							window.location.reload()
						}>
							Reload
						</div>

					</div>
				</nav>
			</div>
			<PopUp show={show} setShow={setShow} width="150vh" height="80vh" Component={< AddWine />} />
			<PopUp show={showDelete} setShow={setShowDelete} width="350px" height="250px" Component={
				<div className='mt-20 flex flex-col space-y-4'>
					<span className='w-full text-center text-xl'>{gridRef.current?.api.getSelectedRows().length} 筆資料將被刪除 </span>
					<div className='flex space-x-6'>
						<button className="my-auto rounded-lg bg-red-400 text-white p-3" onClick={() => {
							const seletedRows = gridRef.current?.api.getSelectedRows()
							const deleteIds: string[] = []
							seletedRows?.forEach((row) => {
								deleteIds.push(row._id!)
							})
							axiosCredentialsInstance.delete('/wine/delete', { data: { ids: deleteIds } })
							gridRef.current?.api.applyTransaction({ remove: seletedRows })
							setShowDelete(false)
						}}>
							確定刪除
						</button>
						<button className="my-auto rounded-lg bg-gray-400 text-white p-3" onClick={() => {
							setShowDelete(false)
						}}>
							取消
						</button>
					</div>
				</div>
			} />
		</>
	);
}

export default WineGrid;
