import { WineAppellation } from '../interfaces'
import { useState, useEffect } from 'react'
import axiosCredentialsInstance from "../Axios/axiosCredentials";
import CardContainer from "../components/CardContainer";
import CountryCardContainer from "../components/CountryCardContainer";
import Loading from '../components/Loading';

function WineOrder() {
	const [wineAppellation, setWineAppellation] = useState<WineAppellation | null>(null);
	const [countries, setCountries] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingIcon, setLoadingIcon] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = useState('Red');

	function checkDeleted(incomingData: any, originalData: any) {
		originalData.countries.forEach((country: any) => {

			const hasSameCountry = incomingData.countries.some((latestCountry: any) => latestCountry.country === country.country)

			if (!hasSameCountry) {
				console.log(country.country)
				originalData.countries = originalData.countries.filter((newCountry: any) => newCountry.country !== country.country);
			} else {

				const latestCountry = incomingData.countries.find((latestCountry: any) => latestCountry.country === country.country)

				country.appellations.forEach((appellation: any) => {

					const hasSameAppellation = latestCountry.appellations.some((latestAppellation: any) => latestAppellation.appellation === appellation.appellation)


					if (!hasSameAppellation) {
						console.log(country.country, appellation.appellation);
						const newCountry = originalData.countries.find((newCountry: any) => newCountry.country === country.country);
						newCountry.appellations = newCountry.appellations.filter((newAppellation: any) => newAppellation.appellation !== appellation.appellation);
					}

					else {
						const latestAppellation = latestCountry.appellations.find((latestAppellation: any) => latestAppellation.appellation === appellation.appellation)

						appellation.subAppellations.forEach((subAppellation: any) => {

							const hasSameSubAppellation = latestAppellation.subAppellations.includes(subAppellation)
							if (!hasSameSubAppellation) {
								console.log(country.country, appellation.appellation, subAppellation);

								const newCountry = originalData.countries.find((newCountry: any) => newCountry.country === country.country);
								const newAppellation = newCountry.appellations.find((newAppellation: any) => newAppellation.appellation === appellation.appellation);

								newAppellation.subAppellations = newAppellation.subAppellations.filter((newSubAppellation: any) => newSubAppellation !== subAppellation);

							}
						})
					}
				})
			}
		})
	}

	function checkAdded(incomingData: any, originalData: any) {
		incomingData.countries.forEach((country: any) => {
			const hasSameCountry = originalData.countries.some((originalCountry: any) => originalCountry.country === country.country);

			if (!hasSameCountry) {
				originalData.countries.push(country);
			} else {
				const originalCountry = originalData.countries.find((originalCountry: any) => originalCountry.country === country.country);

				country.appellations.forEach((appellation: any) => {
					const hasSameAppellation = originalCountry.appellations.some((originalAppellation: any) => originalAppellation.appellation === appellation.appellation);

					if (!hasSameAppellation) {
						originalCountry.appellations.push(appellation);
					} else {
						const originalAppellation = originalCountry.appellations.find((originalAppellation: any) => originalAppellation.appellation === appellation.appellation);

						appellation.subAppellations.forEach((subAppellation: any) => {
							const hasSameSubAppellation = originalAppellation.subAppellations.includes(subAppellation);

							if (!hasSameSubAppellation) {
								console.log(country.country, appellation.appellation, subAppellation);
								originalAppellation.subAppellations.push(subAppellation);
							}
						});
					}
				});
			}
		});
	}



	useEffect(() => {
		const fetchData = async () => {
			if (selectedOption) {
				setLoading(true);

				try {
					const incomingData = await axiosCredentialsInstance.get(`/appellation/fetch/${selectedOption}`);

					const res = await axiosCredentialsInstance.get(`/appellation/${selectedOption}`);

					checkDeleted(incomingData.data, res.data)

					checkAdded(incomingData.data, res.data)

					setWineAppellation(res?.data);

					axiosCredentialsInstance.put(`/appellation`, res.data);

					setLoading(false);

				} catch (error) {
					try {
						const res = await axiosCredentialsInstance.get(`/appellation/fetch/${selectedOption}`);
						await axiosCredentialsInstance.post(`/appellation`, res.data);
						setWineAppellation(res.data);
						setLoading(false);
					} catch (err) {
						console.log(err);
					}
				}
			} else {
				setWineAppellation(null); // Reset wineAppellation if no option is selected
			}
		};


		fetchData();
		setLoading(false);
	}, [selectedOption]);


	function checkCountryAdded(incomingData: any, originalData: any) {
		incomingData.countries.forEach((country: any) => {

			const hasSameCountry = originalData.countries.some((originalCountry: any) => originalCountry === country);
			if (!hasSameCountry) {
				originalData.countries.push(country);

			}
		});
	}

	function checkCountryDeleted(incomingData: any, originalData: any) {
		originalData.countries.forEach((country: any) => {
			const hasSameCountry = incomingData.countries.some((latestCountry: any) => latestCountry === country)
			if (!hasSameCountry) {
				originalData.countries = originalData.countries.filter((newCountry: any) => newCountry !== country);
			}
		})
	}


	useEffect(() => {
		const fetchData = async () => {
			if (selectedOption) {
				setLoading(true);

				try {
					const incomingData = await axiosCredentialsInstance.get(`/appellation/fetch-country/${selectedOption}`);

					const res = await axiosCredentialsInstance.get(`/appellation/country-order/${selectedOption}`);

					checkCountryDeleted(incomingData.data, res.data)

					checkCountryAdded(incomingData.data, res.data)

					setCountries(res?.data.countries);

					axiosCredentialsInstance.put(`/appellation/country-order`, res.data);

					setLoading(false);

				} catch (error) {
					try {
						const res = await axiosCredentialsInstance.get(`/appellation/fetch-country/${selectedOption}`);

						await axiosCredentialsInstance.post(`/appellation/country-order`, res.data);

						setCountries(res.data.countries);

						setLoading(false);

					} catch (err) {
						console.log(err);
					}
				}
			} else {
				setCountries([]); // Reset wineAppellation if no option is selected
			}
		};


		fetchData();
		setLoading(false);
	}, [selectedOption]);

	useEffect(() => {
		setLoadingIcon(true);
		setTimeout(() => {
			setLoadingIcon(false);
		}
			, 3000)

	}, [selectedOption])


	return (
		<div className='flex flex-col px-5 bg-slate-50'>
			{loading ? <Loading /> : (
				<>
					<div className='w-full flex justify-center mb-2'>
						<div className="flex mt-5">

							<button className={`py-2 px-4 my-4 rounded-lg shadow-md flex justify-center text-2xl align-middle w-36 h-18 ${selectedOption === 'Red' ? "text-gray-100 bg-primary" : ""}`} onClick={() => setSelectedOption('Red')}>
								<div className='my-auto'>Red</div>
							</button>

							<button className={`py-2 px-4 my-4 rounded-lg shadow-md flex justify-center text-2xl align-middle w-36 h-18 ${selectedOption === 'White' ? "text-gray-100 bg-primary" : ""}`} onClick={() => setSelectedOption('White')}>
								<div className='my-auto'>White</div>
							</button>
						</div>
					</div>

					{loadingIcon ? <Loading /> :
						<div className='flex'>
							<div className='p-4'>
								{!loading && countries.length !== 0 && <CountryCardContainer countries={countries} style={selectedOption} />}
							</div>
							<div className="flex w-3/4 overflow-x-scroll">
								{!loading &&
									wineAppellation &&
									wineAppellation.countries.map((country) => {
										return (
											<div className="mr-5 p-4" key={country.country}>
												<CardContainer
													appellations={country.appellations}
													country={country.country}
													style={wineAppellation.style}
												/>
											</div>
										);
									})}
							</div>
						</div>
					}
				</>
			)}
		</div>
	);
}


export default WineOrder;