const renderMixedText = (text: string) => {
    const chineseRegex = /[\u4e00-\u9fa5]/; // Regular expression to match Chinese characters
  
    const parts = [];
    let currentPart = '';
  
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
  
      if (chineseRegex.test(char)) {
        // Character is Chinese
        if (currentPart) {
          parts.push(currentPart);
          currentPart = '';
        }
        parts.push(<span key={i} style={{ fontFamily: "'msjh', normal" }}>{char}</span>);
      } else {
        // Character is not Chinese
        currentPart += char;
      }
    }
  
    if (currentPart) {
      parts.push(currentPart);
    }
    return parts;
  };
  

export default renderMixedText;