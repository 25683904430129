import axios from 'axios';
const baseURL = 'https://cellar.fulwon-group.com/api/';

const axiosFileCredential = axios.create({
    withCredentials: true,
    baseURL: baseURL,
    headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default axiosFileCredential;