function PageNotFound() {
	return (
		<div className="flex items-center justify-center h-screen">
			<div className="text-center">
				<h1 className="text-4xl font-bold mb-4">Page Not Found</h1>
				<p className="text-gray-600">The requested page could not be found.</p>
			</div>
		</div>
	)

}

export default PageNotFound