import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import '../App.css'
import { TbUpload } from "react-icons/tb"
import { CgFileDocument } from "react-icons/cg"
import { RiListUnordered, RiLogoutBoxRLine } from "react-icons/ri"
import { FaRegPaperPlane } from "react-icons/fa"
import { backgroundColor } from '../static/index'
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io"
import LogOutPopUp from "./LogOutPopUp";

function Navbar(props: any) {
    const [show, setShow] = useState(true);
    const [showLogout, setShowLogout] = useState(false);
    const [arrowDirection, setArrowDirection] = useState(true);
    const location = useLocation();


  const toggleNavbar = () => {
    setShow(!show);
    if(show){
      setTimeout(() => {
        setArrowDirection(!arrowDirection);
      }, 400);
    }else{
      setArrowDirection(!arrowDirection);
    }
    };

    useEffect(() => {
      const handleMouseEnter = (e: MouseEvent) => {
        const mouseX = e.clientX;
        if (mouseX <= 20) {
          setShow(true);
          setArrowDirection(true);
        }
        if (mouseX > 128) {
          setShow(false);
          setTimeout(() => {
            setArrowDirection(false);
          }, 400);
        }
      };
      window.addEventListener("mousemove", handleMouseEnter);    
      return () => {
        window.removeEventListener("mousemove", handleMouseEnter);
      };
    }, []);
    
    // hover:bg-yellow-300 hover:rounded-lg hover:shadow-md
return (
        <>
        <nav
        className={`fixed top-0 h-screen w-32 flex flex-col shadow-lg z-10 justify-between pt-12 pb-16 transition-all duration-500 ${
          show ? "left-0" : "-left-32"
        }`}
          style={{ backgroundColor: backgroundColor, backdropFilter: "blur(2px)" }}
        >
        <img src="https://res.cloudinary.com/dtziypksf/image/upload/v1687521803/fulwon_logo_iwyfxt.ico" alt="logo" className="w-16 mx-auto" />

        <div className="flex flex-col items-center w-full space-y-12 text-gray-600">
            <Link to="/" className={`text-sm py-2  ${location.pathname === '/' ? ' rounded-lg text-gray-100 shadow-md' : ''} hover:scale-110 transition-transform duration-300 ${location.pathname === '/' ? "bg-primary" : "" }`} >
            <div className="flex flex-col space-y-2 w-20">
                <FaRegPaperPlane className="mx-auto" size={25} />
                <div className="mx-auto">出單</div>
            </div>
          </Link>

          <Link to="/management" className={`text-sm py-2 ${location.pathname === '/management' ? ' rounded-lg text-gray-100 shadow-md' : ''}  hover:scale-110 transition-transform duration-300 ${location.pathname === '/management' ? "bg-primary" : "" }`} >
            <div className="flex flex-col space-y-2 w-20">
                < CgFileDocument className="mx-auto" size={25} />
                <div className="mx-auto">管理</div>
            </div>
          </Link>

          <Link to="/order" className={`text-sm py-2 ${location.pathname === '/order' ? ' rounded-lg text-gray-100 shadow-md' : ''}  hover:scale-110 transition-transform duration-300 ${location.pathname === '/order' ? "bg-primary" : "" }`}>
            <div className="flex flex-col space-y-2 w-20">
                <RiListUnordered className="mx-auto" size={25} />
                <div className="mx-auto">順序</div>
            </div>
          </Link>

          <Link to="/upload" className={`text-sm py-2 ${location.pathname === '/upload' ? ' rounded-lg text-gray-100 shadow-md' : ''}  hover:scale-110 transition-transform duration-300 ${location.pathname === '/upload' ? "bg-primary" : "" }`}>
            <div className="flex flex-col space-y-2 w-20">
              <TbUpload className="mx-auto" size={25} />
              <div className="mx-auto">上傳</div>
            </div>
          </Link>
          <div className="flex flex-col space-y-2 w-20 cursor-pointer py-2 hover:scale-110 transition-transform duration-300" onClick={() => setShowLogout(true)}>
            <RiLogoutBoxRLine className="mx-auto" size={25} />
            <div className="text-sm mx-auto">
                登出
            </div>
          </div>
        </div>
        { 
          arrowDirection &&
            <div className={`absolute flex items-center justify-center cursor-pointer`} onClick={toggleNavbar} style={{ left: "6.5rem", top: "50%" }}>
              <div className="w-6 h-8 rounded-md flex items-center justify-center bg-white text-yellow-500">
                <IoIosArrowBack className="mx-auto" size={25} />
              </div>
            </div>
        }
        {
          !arrowDirection &&
            <div className={`absolute flex items-center justify-center cursor-pointer`} onClick={toggleNavbar} style={{ left: "7.5rem", top: "50%" }}>
              <div className="w-6 h-8 rounded-md flex items-center justify-center bg-white text-yellow-500">
                <IoIosArrowForward className="mx-auto" size={25} />
              </div>
            </div>
        }
      </nav>
        <LogOutPopUp show={showLogout} setShow={setShowLogout}/>
      </>
  );
}

export default Navbar;
