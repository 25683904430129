import '../App.css'
function MessagePopup(props: any) {

return (
    <div className={`absolute top-5 inset-x-0 flex justify-center  ${props.show ? "" : "hidden"} h-10`}>
        <div className="rounded-lg text-center text-xl px-4 py-1 bg-white shadow-lg ">
            {props.messagePopup}
        </div>
    </div>
  );
}

export default MessagePopup;
