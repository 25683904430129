import { BrowserRouter, Route, Routes } from "react-router-dom";
import WineDashboard from "./pages/wineDashboard";
import WineGrid from "./pages/WineGrid";
import WineOrder from "./pages/WineOrder";
import LoginView from "./pages/LoginView";
import DragDropFile from "./pages/DragDropFile";
import PageNotFound from "./pages/PageNotFound";
import ProtectedRoute from "./pages/ProtectedRoute";
import WineCustomerDashboard from "./pages/WineCustomerDashboard";
import Navbar from "./components/NavBar";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginView />} />
          <Route path="/menu" element={<WineCustomerDashboard restaurant={null}/>} />
          <Route path="/menu/frenchie-frenchie" element={<WineCustomerDashboard restaurant={"frenchie-frenchie"} />} /> 
          <Route path="/menu/la-maison-de-win" element={<WineCustomerDashboard restaurant={"la-maison-de-win"}/>} /> 
          <Route path="/menu/*" element={<PageNotFound />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute
                authenticationPath={"/login"}
                outlet={
                  <div className="flex-grow">
                    <DndProvider backend={HTML5Backend}>
                      <Navbar />
                      <Routes>
                        <Route
                          path="/"
                          element={<WineDashboard />}
                        />
                        <Route
                          path="/management"
                          element={<WineGrid />}
                        />
                        <Route
                          path="/order"
                          element={<WineOrder />}
                        />
                        <Route
                          path="/upload"
                          element={<DragDropFile />}
                        />
                        <Route
                          path="*"
                          element={<PageNotFound />}
                        />
                      </Routes>          
                    </DndProvider>
                  </div>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
