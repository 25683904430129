import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosCredentialsInstance from "../Axios/axiosCredentials";
function LoginView() {
	const Navigate = useNavigate();
	const [formData, updateFormData] = useState({
		username: "",
		password: "",
	});
	const [usernameError, setUsernameError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	const handleChange = (e: any) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmitSignIn = (e: any) => {
		e.preventDefault();
		axiosCredentialsInstance.post('/session/login', {
			username: formData.username,
			password: formData.password,
		})
			.then((res) => {
				Navigate('/')
				window.location.reload()
			})
			.catch((err: any) => {
				setUsernameError(err.response.data.error === "username" ? true : false)
				setPasswordError(err.response.data.error === "password" ? true : false)

			})

	};

	return (
		<div className="flex flex-col h-screen justify-center bg-gray-100 pb-36">
			<div className="mb-12 flex justify-center">
				<img src="https://res.cloudinary.com/dtziypksf/image/upload/v1687521803/fulwon_logo_iwyfxt.ico" alt="logo" className="w-16" />
				<div className="flex flex-col mx-4 py-1 text-gray-500">
					<h1 className="text-3xl">富王大飯店</h1>
					<h3 className="text-xl">FULWON HOTEL</h3>
				</div>
			</div>
			<div className="relative w-full md:w-152 mx-auto pb-3 rounded-3xl shadow-lg bg-gray-50">

				<form noValidate className="flex h-60" onSubmit={handleSubmitSignIn}>
					<div className="flex flex-col w-full my-auto">
						<div className="my-4 w-2/3 mx-auto">
							<input
								type="text"
								id="username"
								name="username"
								placeholder="Account"
								className={`w-full border h-10 rounded-xl p-4 ${usernameError ? "border-red-500" : ""
									}`}
								onChange={handleChange}
								onBlur={handleChange}
							/>
							{usernameError && (
								<p className="text-red-500 mt-1">帳號錯誤</p>
							)}
						</div>
						<div className="my-4 w-2/3 mx-auto">
							<input
								type="password"
								id="password"
								name="password"
								placeholder="Password"
								className={`w-full border h-10 rounded-xl p-4 ${passwordError ? "border-red-500" : ""
									}`}
								onChange={handleChange}
								onBlur={handleChange}
							/>
							{passwordError && (
								<p className="text-red-500 mt-1">密碼錯誤</p>
							)}
						</div>
						<div className="w-2/3 mx-auto flex justify-end">
							<button
								type="submit"
								className="w-24 bg-secondary text-white h-10 py-2 px-4 rounded-2xl"
							>
								LOGIN
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

export default LoginView;
