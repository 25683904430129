import { useEffect, useState, useContext } from "react";
import { Wine } from "../interfaces";
import axiosCredentialsInstance from "../Axios/axiosCredentials";
import { TbUpload } from "react-icons/tb";
import { RxCross1 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";
import { BiArchiveIn } from "react-icons/bi";
import { wineTranslationMap } from '../static/index'
import { UploadContext } from "../context/UploadContext";
import MessagePopup from "./MessagePopup";


function UpdatedItem(props: any) {
  const { quantity, setQuantity } = useContext(UploadContext);
  const { updatedItem } = props;
  const { action, originalWineData, importedWineData, differentFields } = updatedItem;
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [fadeOut, setFadeOut] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  
  const [wineData, setWineData] = useState<Wine>(() => {
    if (action === "added") {
      return {
        ...importedWineData,
        wine_by_glass_ff: false,
        wine_by_glass_win: false,
        archived: importedWineData.inventory === 0 ? true : false,
        sommeliers: false,
      };
    }
  
    if (action === "updated") {
      const inventoryField = differentFields.find((field: any) => field.changedKey === "inventory");
  
      if (inventoryField && inventoryField.newValue === 0) {
        return {
          ...originalWineData,
          ...differentFields.reduce((result: any, field: any) => {
            result[field.changedKey] = field.newValue;
            return result;
          }, {}),
          archived: true,
        };
      }
  
      if (inventoryField && inventoryField.oldValue === 0) {
        return {
          ...originalWineData,
          ...differentFields.reduce((result: any, field: any) => {
            result[field.changedKey] = field.newValue;
            return result;
          }, {}),
          archived: false,
        };
      }
      else {
        return {
          ...originalWineData,
          ...differentFields.reduce((result: any, field: any) => {
            result[field.changedKey] = field.newValue;
            return result;
          }, {})
        }
      }
    }
  
    return originalWineData;
  });
  
  const [originalWineDataCopy, setOriginalWineDataCopy] = useState<Wine>(() => {
    if (action === "added") {
      return importedWineData;
    }
    
    return originalWineData;
  });
  
  const firstRowFields = ["code", "vintage", "style", "specification", "msrp", "country", "ml", "inventory"];
  const secondRowFields = ["appellation", "sub_appellation", "natural", "wine_by_glass_ff", "wine_by_glass_win", "archived", "sommeliers"];
  const firstRowWidth = ["w-1/9", "w-1/9", "w-1/9", "w-1/3", "w-1/9", "w-1/9", "w-1/9", "w-1/9"];

  
  function showSuccessMessage(message : string) {
    setShowMessage(true);
    setMessage(message);
    setTimeout(() => {
      setShowMessage(false);
      setMessage("");
    }, 2000);
  }
  function handleRequest(action: string, requestFn: Function, successMessage: string) {
    requestFn()
      .then((data: any) => {
        showSuccessMessage(successMessage);
        setQuantity(quantity - 1);
        setFadeOut(true);
        setTimeout(() => {
          setHidden(true);
        }, 300);
      })
      .catch((err :any) => {
        setButtonDisabled(false);
        setShowMessage(true);
        setMessage(err.response.data);
        setTimeout(() => {
          setShowMessage(false);
          setMessage("");
        }, 2000);
      });
  }
  
  const handleSubmit = (action: string) => {
    setButtonDisabled(true);
    switch (action) {
      case "added":
        handleRequest("added", () => axiosCredentialsInstance.post("/wine", wineData), "成功新增");
        break;
  
      case "updated":
        handleRequest("updated", () => axiosCredentialsInstance.put(`/wine/${wineData._id}`, wineData), "成功更新");
        break;
  
      case "archive":
        handleRequest("archive", () => axiosCredentialsInstance.put(`/wine/${wineData._id}`, { ...wineData, archived: true }), "成功典藏");
        break;
  
      case "deleted":
        handleRequest("deleted", () => axiosCredentialsInstance.delete(`/wine/delete`, { data: { ids: [wineData._id] } }), "成功刪除");
        break;
    }
  };
  

  return (
  <>
    <MessagePopup show={showMessage} messagePopup={message} />
    <div
      key={wineData.code}
      className={`flex items-center border-2 ${action === "updated" ? "border-gray-500" : action === "added" ? "border-green-500" : "border-red-500"} rounded-xl bg-white ${
        fadeOut ? "opacity-0 transition-opacity duration-300" : ""
      } ${hidden ? "hidden" : ""}`}
    >
      <div className="flex flex-col w-full">
        <table className="border-collapse w-full border-gray-500 rounded-lg min-h-24">
          <thead>
            <tr>
              {firstRowFields.map((field, index) => {
                const isFieldChanged = differentFields && differentFields.some((f :any) => f.changedKey === field);

                return (
                  <th
                    key={field}
                    className={`pl-2 py-2 text-left border-gray-500 border-r-2 px-2 ${firstRowWidth[index]} ${
                      isFieldChanged ? "bg-red-100" : ""
                    }`}
                  >
                    {wineTranslationMap[field]}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {firstRowFields.map((field, index) => {
                const isFieldChanged = differentFields && differentFields.some((f :any) => f.changedKey === field);

                return (
                  <td
                    key={field}
                    className={`pl-2 text-left border-gray-500 border-r-2 px-2 ${
                      isFieldChanged ? "text-red-500 bg-red-100" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="w-full h-full bg-transparent border-none text-left"
                      defaultValue={
                        isFieldChanged
                          ? differentFields.find((f :any) => f.changedKey === field).newValue
                          : wineData[field]
                      }
                      onChange={(e) => {
                        setWineData({ ...wineData, [field]: e.target.value });
                      }}
                    />
                  </td>
                );
              })}
            </tr>
            {action === "updated" && (
              <tr className="border-gray-500 border-b-2 h-8">
                {firstRowFields.map((field, index) => {
                  const isFieldChanged = differentFields && differentFields.some((f : any) => f.changedKey === field);

                  return (
                    <td
                      key={field}
                      className={`pl-2 text-left border-gray-500 border-r-2 ${
                        isFieldChanged ? "bg-red-100" : ""
                      }`}
                    >
                      {differentFields && differentFields.some((f :any) => f.changedKey === field) ? originalWineDataCopy[field] : ""}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>

        <table className={`border-collapse w-full border-gray-500 ${action === "updated" ? "" : "border-t-2"}`}>
          <thead>
            {secondRowFields.map((field, index) => {
              const isFieldChanged = differentFields && differentFields.some((f :any) => f.changedKey === field);

              return (
                <th
                  key={field}
                  className={`pl-2 py-2 text-left border-gray-500 border-r-2 px-2 ${isFieldChanged ? "bg-red-100" : ""}`}
                >
                  {wineTranslationMap[field]}
                </th>
              );
            })}
          </thead>
          <tbody>
            <tr>
              {secondRowFields.map((field) => {
                const isFieldChanged = differentFields && differentFields.some((f :any) => f.changedKey === field);

                if (field === "wine_by_glass_ff" || field === "wine_by_glass_win" || field === "archived" || field === 'sommeliers') {
                  return (
                    <td
                      key={field}
                      className={`pl-2 text-left border-gray-500 border-r-2 ${isFieldChanged ? "bg-red-100" : ""}`}
                    >
                      <div className="flex items-center mx-4 w-18">
                        <input
                          type="checkbox"
                          className="text-indigo-600 h-5 w-5 ml-2"
                          checked={wineData[field]}
                          onClick={() => {
                            setWineData({ ...wineData, [field]: !wineData[field] });
                          }}
                        />
                      </div>
                    </td>
                  );
                }

                return (
                  <td
                    key={field}
                    className={`pl-2 text-left border-gray-500 border-r-2 ${
                      isFieldChanged ? "text-red-500 bg-red-100" : ""
                    }`}
                  >
                    <input
                      type="text"
                      className="w-full h-full bg-transparent border-none text-left"
                      defaultValue={
                        isFieldChanged
                          ? differentFields.find((f :any) => f.changedKey === field).newValue
                          : wineData[field]
                      }
                      onChange={(e) => {
                        setWineData({ ...wineData, [field]: e.target.value });
                      }}
                    />
                  </td>
                );
              })}
            </tr>
            {action === "updated" && (
              <tr className={`border-gray-500 h-8`}>
                {secondRowFields.map((field, index) => {
                  const isFieldChanged = differentFields && differentFields.some((f :any) => f.changedKey === field);

                  return (
                    <td
                      key={field}
                      className={`text-left border-gray-500 border-r-2 px-2 ${isFieldChanged ? "bg-red-100" : ""}`}
                    >
                      {isFieldChanged ? originalWineDataCopy[field] : ""}
                    </td>
                  );
                })}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="w-32 h-full flex flex-col">
        <div className="flex border-gray-500 border-b-2 w-full h-1/2">
        {!isButtonDisabled && action !== "deleted" && (
          <TbUpload
            size={35}
            className="mx-auto my-auto h-full hover:scale-110 transition-transform duration-300"
            onClick={() => handleSubmit(action)}
          />
        )}
        {(!isButtonDisabled && action === "deleted") && (
          <>
            <AiFillDelete
              size={35}
              className="mx-auto my-auto h-full hover:scale-110 transition-transform duration-300"
              onClick={() => handleSubmit(action)}
            />
            <BiArchiveIn
              size={35}
              className="mx-auto my-auto h-full hover:scale-110 transition-transform duration-300"
              onClick={() => handleSubmit("archive")}
            />
          </>
        )}
        </div>
        <div className="w-full h-1/2">
          <RxCross1
            size={40}
            className="mx-auto my-auto h-full hover:scale-110 transition-transform duration-300"
            onClick={() => {
              setQuantity(quantity - 1);
              setFadeOut(true);
              setTimeout(() => {
                setHidden(true);
              }, 300);
            }}
          />
        </div>
      </div>
    </div>
  </>


  );
}

export default UpdatedItem;
