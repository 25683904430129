import { useState, useEffect } from "react";
import { stripStyleName } from "../static/index";
import renderMixedText from "../static/renderMixedText";
import renderMsrp from "../static/renderMsrp";
import { Wine } from "../interfaces";
import "../App.css";



function WineSingleListingTemplatePage(props: any) {
	const [loading, setLoading] = useState<boolean>(true);
	const [style, setStyle] = useState<string>(props.title);

	useEffect(() => {
		const setData = async () => {
			setLoading(false);
		}
		setData();
		props.setPageIndex((prevPageIndex: any) => ({
			...prevPageIndex,
			[props.page]: [props.title]
		}));
	}, [props.page])



	return (
		<div className={`flex justify-end pr-6 h-pdf bg-white ${props.converting ? "" : "mb-10 shadow-lg"}`} >
			<div className="flex flex-col">
				<div className="flex justify-center mb-6 pr-12">
					<div className="flex items-center justify-center relative" style={{ width: "23.1rem", height: "6rem", backgroundColor: 'rgb(86, 67, 60)' }}>
						<div className="absolute" style={{ left: "-1.5rem", top: "4.5rem" }}>
							<div className="w-12 h-12 rounded-full bg-white"></div>
						</div>
						<h1 className={`text-5xl font-bold text-white text-center ${props.converting ? "mb-8" : ""}`} style={{ fontFamily: '"Garamond", serif' }}>
							{style}
						</h1>
						<div className="absolute" style={{ right: "-1.5rem", top: "4.5rem" }}>
							<div className="w-12 h-12 rounded-full bg-white"></div>
						</div>
					</div>
				</div>

				<div>
					<div className="flex justify-between pt-14 pb-6" style={{ color: 'rgba(34,32,28,200)' }}>
						<h1 className={`relative font-bold ${props.converting ? "tracking-wider" : ""}`} style={{ fontFamily: "'Concept Bold' , normal", fontSize: '1.2rem', left: '-4rem' }}></h1>
						<h1 className="font-bold" style={{ fontFamily: "'Concept Bold' , normal", fontSize: '1.2rem' }}>NT.</h1>
					</div>
					<div
						className="pl-6 pt-8"
						style={{
							width: "43rem",
							borderColor: '#564540',
							borderLeft: '0.125rem solid #451A03',
							borderTop: '0.125rem solid #451A03',
							borderTopLeftRadius: '2rem 2.6rem',
						}}
					>
						<div className="grid grid-cols-1" style={{ color: 'rgba(45,44,43,255)' }}>

							{!loading && props.wineSweetPage.map((wine: Wine, index: number) => (
								<div key={index}>

									<div className="rounded text-sm flex relative">
										<p className="absolute" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem', left: "-5rem" }}>{wine.code.slice(wine.code.length - 4, wine.code.length)}</p>
										{wine.natural && (
											<img
												className={`w-4 h-4 absolute`}
												src="https://res.cloudinary.com/dtziypksf/image/upload/v1685543916/istockphoto-465357730-612x612_rvyejg.jpg"
												style={{ left: "-2rem", top: `${props.converting ? "50%" : "6px"}` }}
											/>
										)}
										<div className="w-full flex" style={{ paddingTop: '0.375rem', paddingBottom: '' }}>
											<div className="w-136">
												{renderMixedText(stripStyleName(wine.specification))}
											</div>
										</div>
										<div className="flex absolute mr-4" style={{ right: "2rem" }}>
											<div className="w-8 ml-2 mr-5" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>{wine.vintage}</div>
										</div>
										<div className="flex" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>
											{renderMsrp(wine.msrp.toLocaleString())}

										</div>
									</div>
									<div className="rounded text-sm flex relative">
										<div className="w-full flex">
											<div className="text-gray-500">
												{`${wine.appellation ? wine.appellation.toUpperCase() + ',' : ''} ${wine.country ? wine.country.toUpperCase() : ''}`}
											</div>
										</div>
									</div>
								</div>
							))}

						</div>
					</div>
				</div>

			</div>
			<div className="absolute mt-260 text-gray-400">{props.page}</div>
		</div>
	);
}

export default WineSingleListingTemplatePage;
