import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useState, useRef, useCallback } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Item, CardProps, DragItem } from '../interfaces'
import update from 'immutability-helper'
import { SubCard } from './SubCard'
import axiosCredentialsInstance from '../Axios/axiosCredentials'
import { MdDragHandle } from "react-icons/md"
const elementStyle = {
  padding: '0.5rem 1rem',
  margin: '1.5rem',
  marginBottom: '.5rem',
  border: '1px solid white',
  cursor: 'move',
}

let dragFirstIndex: null | number = null

function debounce<T extends (...args: any[]) => any>(callback: T, delay: number): T {
  let timerId: ReturnType<typeof setTimeout> | null;

  return function (this: any, ...args: Parameters<T>) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      callback.apply(this, args);
    }, delay) as any;
  } as T;
}


const debouncedUpdateDrop = debounce(async (dragStartIndex: number, dropEndIndex: number, style: string, country: string) => {
  console.log('executed')
  axiosCredentialsInstance.get(`/appellation/swap/${style}/${country}?dragIndex=${dragFirstIndex}&dropIndex=${dropEndIndex}`);
  dragFirstIndex = null
}, 500);


export const Card: FC<CardProps> = ({ id, text, index, style, country, subAppellations, moveCard }) => {
  const [subCards, setSubCards] = useState(subAppellations.map((subAppellation, index) => ({
    id: index + 1,
    text: subAppellation,
  })))

  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: country,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },

    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // console.log(clientOffset)
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if(dragFirstIndex === null){
        dragFirstIndex = dragIndex
      }


      updateDrop(dragIndex, hoverIndex)
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const updateDrop = (dragStartIndex: number, dropEndIndex: number) => {
    debouncedUpdateDrop(dragStartIndex, dropEndIndex, style, country);
  };


  const [{ isDragging }, drag] = useDrag({
    type: country,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))



  const moveSubCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSubCards((prevCards: Item[]) =>
    update(prevCards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevCards[dragIndex] as Item],
      ],
    }),
  )
  }, [])

  const [showAll, setShowAll] = useState(false);

  const subCardsToShow = showAll ? subCards : subCards.slice(0, 3);

  const handleLoadMore = () => {
    setShowAll(true);
  };



  return (
    <div ref={ref} style={{ ...elementStyle, opacity }} data-handler-id={handlerId} className='rounded-xl flex flex-col shadow-lg bg-gray-50'>
      <div className='flex justify-center mb-2'><MdDragHandle size={25}/></div>
      <h2 className='flex justify-center text-xl'>{text === 'non-appellation' ? "無產區" : text}</h2>
      {subCardsToShow.map((card, i) => (
        <SubCard
          key={card.id}
          index={i}
          id={card.id}
          text={card.text}
          appellationType={country + '-' + text}
          style={style}
          country={country}
          appellation={text}
          moveSubCard={moveSubCard}
        />
      ))}
      {subCards.length > 2 && !showAll && (
        <button onClick={handleLoadMore} className='text-blue-500 underline mt-2 mx-auto'>Load More</button>
      )}
    </div>
  )
}
