import { Transition } from "@headlessui/react";

function PopUp(props: any) {
return (
    <Transition
    show={props.show}
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    >   
    <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className="bg-white rounded-lg p-8" style={{ width: props.width, height: props.height}}>
        <div className="flex justify-end w-full">
            <button
            className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none absolute"
            onClick={() => props.setShow(false)}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-gray-500 hover:text-gray-600"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                fillRule="evenodd"
                d="M3.646 16.354a.5.5 0 10.708-.708L10 10.707l5.646 5.647a.5.5 0 10.708-.708L10.707 10l5.647-5.646a.5.5 0 00-.708-.708L10 9.293 4.354 3.646a.5.5 0 00-.708.708L9.293 10l-5.647 5.646z"
                clipRule="evenodd"
                />
            </svg>
            </button>
        </div>
        <div className="flex justify-center my-auto w-full h-full">
            {props.Component}       
        </div>
        </div>
    </div>
    </Transition>
)
}

export default PopUp;
