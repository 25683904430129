import axios from 'axios';

const baseURL = 'https://cellar.fulwon-group.com/api/';

const axiosCredentialsInstance = axios.create({
    withCredentials: true,
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

export default axiosCredentialsInstance;