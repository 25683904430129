import { Transition } from "@headlessui/react";
import { BsExclamationTriangle } from 'react-icons/bs';
import axiosCredentialsInstance from "../Axios/axiosCredentials";
function LogOutPopUp(props: any) {
return (
    <Transition
    show={props.show}
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    >   
    <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className="bg-white rounded-xl p-8 border-primary border-2 shadow-lg" style={{ width: '400px', height: '300px'}}>
            <div className="flex justify-center text-red-500">
                <BsExclamationTriangle size={50} />
            </div>
            
            <div className="flex justify-center my-auto w-full h-3/5">
                <div className="flex flex-col">
                <span className="mt-4 text-4xl text-center">LOG OUT ?</span>
                <span className="mt-4 text-center text-gray-500">Are you sure you want to log out?</span>
                </div>
            </div>

            <div className="flex justify-center w-full">
                <button
                className="p-2 rounded-full bg-gray-200  hover:scale-110 transition-transform duration-300  focus:outline-none mr-20"
                onClick={() => {
                    axiosCredentialsInstance.post('/session/logout').then((res) => {
                        window.location.reload();
                    })
                }}
                >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
                >
                <path
                    fillRule="evenodd"
                    d="M17.707 6.293a.999.999 0 00-1.414 0L8 14.586 3.707 10.293a.999.999 0 10-1.414 1.414l5 5a.997.997 0 001.414 0l10-10a.999.999 0 000-1.414z"
                    clipRule="evenodd"
                />
                </svg>

                </button>

                <button
                className="p-2 rounded-full bg-primary  hover:scale-110 transition-transform duration-300 focus:outline-none"
                onClick={() => props.setShow(false)}
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                    fillRule="evenodd"
                    d="M3.646 16.354a.5.5 0 10.708-.708L10 10.707l5.646 5.647a.5.5 0 10.708-.708L10.707 10l5.647-5.646a.5.5 0 00-.708-.708L10 9.293 4.354 3.646a.5.5 0 00-.708.708L9.293 10l-5.647 5.646z"
                    clipRule="evenodd"
                    />
                </svg>
                </button>
            </div>
        </div>
    </div>
    </Transition>
)
}

export default LogOutPopUp;
