import { createContext } from "react";


type UploadContextType = {
    quantity: number;
    setQuantity: (quantity: number) => void;
}

export const UploadContext = createContext<UploadContextType>({} as UploadContextType);


