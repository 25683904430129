import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-orange-400"></div>
      <p className="mt-5 text-ivory text-xl font-semibold">請稍等...</p>
    </div>
  );
};

export default Loading;
