import { useState } from 'react';
import { GrRefresh } from 'react-icons/gr';
import UpdatedItem from './UpdatedItem';
import { UploadContext } from '../context/UploadContext';

function Updatedlist(props: any) {
  const [quantity, setQuantity] = useState(props.updatedWines.length);

  return (
    <div className='flex flex-col h-full'>
      <UploadContext.Provider value={{ quantity, setQuantity }}>
        <div className='flex justify-between w-full px-20 py-4'>
          <div >
            <h1 className='text-2xl font-bold'>總共 : {quantity}筆資料</h1>
          </div>
          <button className='w-8 h-8 border-2 border-gray-500 bg-white my-auto rounded-lg hover:scale-110 transition-transform duration-300' onClick={()=>{
            window.location.reload()
          }}>
            <GrRefresh className='mx-auto my-auto' size={22} />
          </button>
        </div> 
        <div className='w-11/12 h-11/12 mx-auto my-auto overflow-scroll rounded-xl '>
          <div className="grid grid-cols-1 gap-6 p-4">
            {props.updatedWines.map((updatedItem: any, index: number) => {
              return <UpdatedItem updatedItem={updatedItem} key={index}/>;
            })}
          </div>
        </div>
      </UploadContext.Provider>
    </div>
  );
}

export default Updatedlist;
