import { CountryCard } from "../components/CountryCard";
import update from 'immutability-helper'
import { Item } from '../interfaces'
import { useCallback, useState, useEffect } from 'react'


function CountryCardContainer(props: any) {
    const [cards, setCards] = useState(props.countries.reduce((result:any, country:any) => [...result, {id: country, text: country}], []))
    useEffect(()=>{
        setCards(props.countries.reduce((result:any, country:any) => [...result, {id: country, text: country}], []))
      }, [props.countries])

      const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: Item[]) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex] as Item],
            ],
          }),
        )
      }, [])
  
      // const renderCard = useCallback(
      //   (card: { id: number; text: string; subAppellation: string[] }, index: number) => {
      //     return (
      //       <CountryCard
      //           key={card.id}
      //           index={index}
      //           id={card.id}
      //           text={card.text}
      //           moveCountryCard={moveCard}
      //           style={props.style}
      //           country={props.country}
      //       />
      //     )
      //   },
      //   [],
      // )
  
      return (
        <div className="flex flex-col w-96 rounded-lg p-3" style={{height: "80vh", overflow: "scroll", backgroundColor: "#f0f0f0"}}>
          <div className="flex justify-center"><h1 className="text-3xl">國家順序</h1></div>
          <div className="bg-white rounded-xl shadow-lg my-3 px-4">{cards.map((card:any, i:any) => {
            return (
              <CountryCard 
                key={card.id}
                index={i}
                id={card.id}
                text={card.text}
                moveCountryCard={moveCard}
                style={props.style}
                country={props.country}
              />
            )
          })}</div>
      
        </div>
      )
}

export default CountryCardContainer;