import { WineData } from "../interfaces";

type Data = { [key: string]: string[] };

type InvertedEntry = {
    [key: string]: string;
};
export function getKeys(data: any, parentKey: string = ''): string[] {
    let keys: string[] = [];
    
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const newKey = parentKey ? `${parentKey},${key}` : key;
            
            if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
                keys = keys.concat(getKeys(data[key], newKey));
            } else if (Array.isArray(data[key])) {
                keys.push(newKey);
            }
        }
    }

    return keys;
}

export function invertKeyValue(data: Data): InvertedEntry[] {
    const invertedDict: { [key: string]: Set<number> } = {};

    for (const [key, values] of Object.entries(data)) {
        const numericKey = parseInt(key, 10);
        for (const value of values) {
            if (!invertedDict[value]) {
                invertedDict[value] = new Set();
            }
            invertedDict[value].add(numericKey);
        }
    }

    const result: InvertedEntry[] = [];
    for (const [value, keys] of Object.entries(invertedDict)) {
        // const sortedKeys = Array.from(keys).sort((a, b) => a - b);
        const minimumKey = Math.min(...Array.from(keys));
        let formattedValue: string;
        formattedValue = `${minimumKey}`;
        result.push({ [value]: formattedValue });
    }

    return result;
}
type DataEntry = { [key: string]: string };

export function transformRedList(dataList: DataEntry[]): DataEntry[] {
    const transformedDict: { [key: string]: string[] } = {};

    dataList.forEach(entry => {
        for (const [key, value] of Object.entries(entry)) {
            const parts = key.split(',');
            if (parts.length >= 3 && parts[0] === 'Red' && parts[1] === 'France') {
                const appellation = parts[2];
                const subAppellation = parts.slice(3).join(',');

                var newKey
                if (subAppellation !== 'non-sub-appellation') {
                    newKey = `${capitalizeFirstLetter(appellation)}${subAppellation ? ',  ' + capitalizeFirstLetter(subAppellation) + `,  ${parts[0]}` : `  ${parts[0]}`}`;
                } else {
                    newKey = `${capitalizeFirstLetter(appellation)} Red`;
                }
                if (!transformedDict[newKey]) {
                    transformedDict[newKey] = [];
                }
                transformedDict[newKey].push(value);
            } else {
                if (!transformedDict[key]) {
                    transformedDict[key] = [value];
                } else {
                    transformedDict[key].push(value);
                }
            }
        }
    });

    return Object.keys(transformedDict).map(key => {
        const values = transformedDict[key];
        return { [key]: values.join(' - ') };
    });
}

export function processWineData(data: any[], wineType: string): any[] {
    const groupedData: any = {};
    const otherData: any[] = [];

    data.forEach(item => {
        const key = Object.keys(item)[0];
        const value = item[key];
        if (key.startsWith(`${wineType},`)) {
            const parts = key.split(',');
            // console.log(parts)
            if (parts.length > 1) {
                const country = parts[1];
                const appellation = parts[2];
                if (appellation.toLowerCase() === 'bordeaux' || appellation.toLowerCase() === 'bourgogne') {
                    if (!groupedData[`${country}-${appellation}`]) {
                        groupedData[`${country}-${appellation}`] = value;
                    } else {
                        groupedData[`${country}-${appellation}`] = mergeRanges(groupedData[`${country}-${appellation}`], value);
                    }
                } else {
                    if (!groupedData[country]) {
                        groupedData[country] = value;
                    } else {
                        groupedData[country] = mergeRanges(groupedData[country], value);
                    }
                }

            }
        } else {
            otherData.push(item);
        }
    });

    const result: any[] = [];
    var firstTime = true;

    data.forEach((item, idx) => {
        const key = Object.keys(item)[0];
        if (key.startsWith(`${wineType},`)) {
            const parts = key.split(',');
            if (parts.length > 1) {
                const country = parts[1];
                const appellation = parts[2];
                if (groupedData[country]) {
                    if (firstTime) {
                        result.push({[`${wineType}`]: groupedData[country]});
                        firstTime = false;
                    }
                    result.push({ [`${wineType}, ${country}`]: groupedData[country] });

                    delete groupedData[country];
                } else if (groupedData[`${country}-${appellation}`]) {
                    if (firstTime) {
                        result.push({[`${wineType}`]: groupedData[`${country}-${appellation}`]});
                        firstTime = false;
                    }
                    result.push({ [`${wineType}, ${country}, ${appellation}`]: groupedData[`${country}-${appellation}`] });

                    delete groupedData[`${country}-${appellation}`];
                }
            }
        } else {
            result.push(item);
        }
    });



    return [...result];
}


export function combineAdjacentWhiteData(data: any[], wineType: string): any[] {
    const result: any[] = [];
    let i = 0;

    while (i < data.length) {
        const current = data[i];
        const currentKey = Object.keys(current)[0];
        const currentValue = current[currentKey];

        if (currentKey.startsWith(`${wineType},`)) {
            let combinedValue = currentValue;
            let combinedCountries: string[] = [currentKey.split(",")[1] + ","];

            while (i + 1 < data.length) {
                const next = data[i + 1];
                const nextKey = Object.keys(next)[0];
                const nextValue = next[nextKey];

                if (nextKey.startsWith(`${wineType},`) && nextValue === combinedValue) {
                    combinedCountries.push(nextKey.split(",")[1] + ',');
                    i++;
                } else {
                    break;
                }
            }
            result.push({ [`${wineType}, ${combinedCountries.join(" ")}`]: combinedValue });
        } else {
            result.push(current);
        }
        i++;
    }

    return result;
}
export function transformAppellationData(data: any[]): any[] {
    const result: any[] = [];
    let i = 0;

    while (i < data.length) {
        const current = data[i];
        const currentKey = Object.keys(current)[0];
        const currentValue = current[currentKey];

        if (currentKey.includes(",") && !currentKey.startsWith("White,") && !currentKey.startsWith("Red,")) {
            const [appellation, subAppellation] = currentKey.split(",").map(part => part.trim());

            result.push({ [`${appellation} Red`]: "" })
            while (i + 1 < data.length) {
                const next = data[i + 1];
                const nextKey = Object.keys(next)[0];
                const nextValue = next[nextKey];

                if (nextKey.includes(",") && !nextKey.startsWith("White,") && !nextKey.startsWith("Red,")) {
                    result.push({ [`-${nextKey.split(',')[1]}`]: nextValue })
                    i++;
                } else {
                    break;
                }
            }

            result.push(current);
        } else {
            result.push(current);
        }
        i++;
    }

    return result;
}

export function rearrangeWineKeys(data: any[]): any[] {
    return data.map(entry => {
        const key = Object.keys(entry)[0];
        const value = entry[key];

        if (key.startsWith("Red,") || key.startsWith("White,")) {
            const prefix = key.split(',')[0];
            const restOfKey = key.substring(prefix.length + 1).trim().replace(/,\s*/g, ',   ');
            const newKey = `${restOfKey} ${prefix}`;
            return { [newKey]: value };
        }

        return entry;
    });
}
export function splitIntoChunks(data: any, chunkSize: any) {
    const result = [];
    for (let i = 0; i < data.length; i += chunkSize) {
        result.push(data.slice(i, i + chunkSize));
    }
    return result;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function mergeRanges(existing: string, newValue: string): string {
    const existingRange = existing.split(' - ').map(v => parseFloat(v.trim()));
    const newRange = newValue.split(' - ').map(v => parseFloat(v.trim()));

    const minExisting = existingRange[0];
    const maxExisting = existingRange[1] || minExisting;
    const minNew = newRange[0];
    const maxNew = newRange[1] || minNew;

    const min = Math.min(minExisting, minNew);
    const max = Math.max(maxExisting, maxNew);

    return min === max ? `${min}` : `${min}`;
}
