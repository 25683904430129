import { useState, useEffect } from "react";
import { stripStyleName } from "../static/index";
import { Wine } from "../interfaces";
import "../App.css";



function WineSweetPage(props: any) {
	const [loading, setLoading] = useState<boolean>(true);
	const [style, setStyle] = useState<string>("Sweet Wines");
	useEffect(() => {
		const setData = async () => {
			setLoading(false);
		}
		setData();
		props.setPageIndex((prevPageIndex: any) => ({
			...prevPageIndex,
			[props.page]: ['Sweet Wines']
		}));
	},[props.page])


	return (
		<div className={`flex justify-center mb-10 `} style={{ fontFamily: 'Arial', minHeight: '60rem' }} >
			<div className="flex flex-col shadow-lg w-md sm:w-md md:w-lg lg:w-xl px-4 sm:px-6 md:px-4 lg:px-6 xl:px-8 bg-white pb-8">
				<div className="flex justify-center mb-2">
					<div className="flex items-center justify-center relative w-72 md:w-80" style={{ height: "5rem", backgroundColor: 'rgb(86, 67, 60)' }}>
						<div className="absolute" style={{ left: "-1.5rem", top: "4.5rem" }}>
							<div className="w-12 h-12 rounded-full bg-white"></div>
						</div>
						<h1 className={`font-bold text-white text-center`} style={{ fontSize: '2.4rem', fontFamily: '"Garamond", serif' }}>
							{style}
						</h1>
						<div className="absolute" style={{ right: "-1.5rem", top: "4rem" }}>
							<div className="w-12 h-12 rounded-full bg-white"></div>
						</div>
					</div>
				</div>

				<div>
					<div className="flex justify-between pt-12 pb-4 md:pt-14 md:pb-6" style={{ color: 'rgba(34,32,28,200)' }}>
						<h1 className="font-bold" style={{ fontFamily: "'Concept Bold' , normal", fontSize: '1.1rem' }}></h1>
						<h1 className="font-bold" style={{ fontFamily: "'Concept Bold' , normal", fontSize: '1.1rem' }}>NT.</h1>
					</div>
					<div className="flex justify-end">
						<div
							className="pl-4 pt-6 w-11/12"
							style={{
								borderColor: '#564540',
								borderLeft: '0.125rem solid #451A03',
								borderTop: '0.125rem solid #451A03',
								borderTopLeftRadius: '2rem 2.6rem',
							}}
						>
							<div className="grid grid-cols-1" style={{ color: 'rgba(45,44,43,255)' }}>

								{!loading && props.wineSweetPage.map((wine: Wine, index: number) => (
									<div key={index}>

										<div className="rounded flex relative text-sm-spec md:text-md-spec">
											<p className="absolute" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem', left: "-3.8rem" }}>{wine.code.slice(wine.code.length - 4, wine.code.length)}</p>
											{wine.natural && (
												<img
													className={`w-4 h-4 absolute`}
													src="https://res.cloudinary.com/dtziypksf/image/upload/v1685543916/istockphoto-465357730-612x612_rvyejg.jpg"
													style={{ left: "-1.55rem", top: `6px` }}
												/>
											)}
											<div className="w-full flex" style={{ paddingTop: '0.375rem', paddingBottom: '' }}>

												<div className="w-72 md:w-96">
													{stripStyleName(wine.specification)}
												</div>
											</div>
											<div className="flex absolute mr-4" style={{ right: "2rem" }}>
												<div className="w-4 ml-2 sm:mr-1 md:mr-5" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>{wine.vintage}</div>
											</div>
											<div className="flex ml-4" style={{ paddingTop: '0.375rem', paddingBottom: '0.375rem' }}>
												{wine.msrp.toLocaleString()}
											</div>
										</div>
										<div className="rounded flex relative text-sm-spec md:text-md-spe">
											<div className="w-full flex">
												<div className="text-gray-500">
													{`${wine.appellation ? wine.appellation.toUpperCase() + ',' : ''} ${wine.country ? wine.country.toUpperCase() : ''} (${wine.ml}ml)`}
												</div>
											</div>
										</div>
									</div>
								))}

							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="text-gray-400 mt-260 -ml-4 relative">{props.page}</div>
		</div>
	);
}

export default WineSweetPage;
