import { useState, useRef } from "react";
import axiosFileCredential from "../Axios/axiosFileCredentials";
import MessagePopup from "../components/MessagePopup";
import Updatedlist from "../components/UpdatedList";
import { BiCloudUpload } from "react-icons/bi";
import '../static/water.css'
function DragDropFile() {
	const [dragActive, setDragActive] = useState(false);
	const [showMessagePopup, setShowMessagePopup] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [uploadedSucessfully, setUploadedSuccessfully] = useState(false);
	const [updatedWines, setUpdatedWines] = useState<any[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	const handleFiles = function (files: FileList) {
		setUploading(true);
		const formData = new FormData();

		for (let i = 0; i < files.length; i++) {
			formData.append("file", files[i]);
		}
		setUploading(true)
		axiosFileCredential
			.post("/file/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}).then((res) => {
				setUpdatedWines(res.data.differentWines);
				setUploading(false);
				setUploadedSuccessfully(true);
			}).catch((err) => {
				console.log(err);
				setUploading(false);
				setUploadedSuccessfully(false);
				setShowMessagePopup(true);
				setTimeout(() => {
					setShowMessagePopup(false);
				}, 3000);
			})
	};

	const handleDrop = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// console.log(e.dataTransfer.files);
			handleFiles(e.dataTransfer.files);
		}
	};

	const handleChange = function (e: any) {
		e.preventDefault();

		if (e.target.files && e.target.files[0]) {
			// File is selected, handle it here
			handleFiles(e.target.files);
		}
	};

	const onButtonClick = () => {
		inputRef.current?.click();
	};

	return (
		<div className="flex items-center justify-center h-screen bg-slate-100">
			<div className={`w-2/3 h-2/3  ${uploading || uploadedSucessfully ? "hidden" : ""}`}>
				<form
					id="form-file-upload"
					onDragEnter={handleDrag}
					onSubmit={(e) => e.preventDefault()}
					className={`h-full w-full`}
				>
					<input
						ref={inputRef}
						type="file"
						id="input-file-upload"
						multiple={true}
						onChange={handleChange}
						name="test"
					/>
					<label
						id="label-file-upload"
						htmlFor="input-file-upload"
						className={`h-full flex justify-center rounded-xl bg-white shadow-lg  ${dragActive ? "bg-slate-200" : ""
							}`}
					>
						<div className="flex flex-col justify-center text-secondary">
							<div className="w-full">
								<BiCloudUpload size={100} className="mx-auto" />
							</div>
							<p>Drag and drop your file here or upload a file</p>
							<button className="upload-button" onClick={onButtonClick}>
							</button>
						</div>
					</label>
					{dragActive && (
						<div
							id="drag-file-element"
							onDragEnter={handleDrag}
							onDragLeave={handleDrag}
							onDragOver={handleDrag}
							onDrop={handleDrop}
						></div>
					)}
				</form>
			</div>
			{uploading && !uploadedSucessfully && (
				<div className="cup w-2/3 h-2/3 rounded-xl shadow-lg"></div>
			)
			}
			{uploadedSucessfully && <Updatedlist updatedWines={updatedWines} />}
			<MessagePopup show={showMessagePopup} messagePopup={"上傳失敗"} />
		</div>
	);
}


export default DragDropFile;
