import { Card } from "../components/Card";
import update from 'immutability-helper'
import { Item } from '../interfaces'
import { useCallback, useState } from 'react'


function CardContainer(props: any) {
    const [cards, setCards] = useState(props.appellations.reduce((result:any, card:any) => [...result, {id:props.country + '-' + card.appellation, text: card.appellation, subAppellation: card.subAppellations }], []))
   
      const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: Item[]) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex] as Item],
            ],
          }),
        )
      }, [])
  
      const renderCard = useCallback(
        (card: { id: number; text: string; subAppellation: string[] }, index: number) => {
          return (
            <Card
              key={card.id}
              index={index}
              id={card.id}
              text={card.text}
              subAppellations={card.subAppellation}
              style={props.style}
              country={props.country}
              moveCard={moveCard}
            />
          )
        },
        [],
      )
  
      return (
        <div className="flex flex-col w-96 rounded-lg p-3" style={{height: "80vh", overflow: "scroll", backgroundColor: "#f0f0f0"}}>
          <div className="flex justify-center"><h1 className="text-3xl">{props.country}</h1></div>
          <div className="bg-white rounded-xl shadow-lg my-3">{cards.map((card:any, i:any) => renderCard(card, i))}</div>
        </div>
      )
}

export default CardContainer;