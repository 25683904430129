
import { Wine, BubblesData, WineData, WineStyleData, WineAppellationData, WineByGlassData, WineByGlassSubStyleData } from '../interfaces';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import { checkOverflow } from '.';
const maxWeight = 1100;
const sparklingMaxWeight = 1140;
const countryWeight = 142;
const appellationWeight = 40;
const subAppellationWeight = 40;
const wineWeight = 32;
const wineDetailWeight = 52;
const wineOverFlowWeight = 20;

const countryMinWeight = countryWeight + appellationWeight + wineWeight * 2;
const appellationMinWeight = appellationWeight + subAppellationWeight + wineWeight;
const subAppellationMinWeight = subAppellationWeight + wineWeight * 2;

export function categorizeFortified(wines: Wine[]) {
    const fortifiedData: any = {
      sherry: [],
      madeira: [],
      others: [],
    };
  
    wines.forEach((wine) => {
      const style = wine.appellation!;
  
      if (style && style.includes('Sherry Wines')) {
        fortifiedData.sherry.push(wine);
      } else if (style && style.includes('Madeira Wines')) {
        fortifiedData.madeira.push(wine);
      } else if (style && style.includes('Others')) {
        fortifiedData.others.push(wine);
      }
    });
  
    Object.keys(fortifiedData).forEach((key) => {
      if (fortifiedData[key].length === 0) {
        delete fortifiedData[key];
      }
    });
  
    return fortifiedData;
  }
  

export function categorizeWinesByGlassStyle(wines: Wine[]): WineByGlassData[] {
    const categorizedWines: WineByGlassData[] = [];
  
    wines.forEach((wine: Wine) => {
      let style = wine.style;
      let subStyle = 'non-sub-style';
  
      if (style.toLowerCase() === 'fortified wines' || style.toLowerCase() === 'spirits') {
        subStyle = wine.appellation ? wine.appellation : "Others";
      } else if (style.toLowerCase() === 'bubbles') {
        if (wine.appellation?.toLowerCase() === 'champagne' && wine.country?.toLowerCase() === 'france') {
          style = 'Champagne';
        } else {
          style = 'Sparkling';
        }
      }
  
      let categorizedWineData = categorizedWines.find((data) => data.hasOwnProperty(style));
      if (!categorizedWineData) {
        categorizedWineData = { [style]: [] };
        categorizedWines.push(categorizedWineData);
      }
  
      let subStyleData = categorizedWineData[style].find((data) => data.hasOwnProperty(subStyle));
      if (!subStyleData) {
        subStyleData = { [subStyle]: [] };
        categorizedWineData[style].push(subStyleData);
      }
  
      subStyleData[subStyle].push(wine);
    });
  
    return categorizedWines;
  }
  

  export function sortWinesByGlassByMsrp(wineData: WineByGlassData[]): WineByGlassData[] {
    const sortedWineData: WineByGlassData[] = [];
  
    for (const data of wineData) {
      const sortedData: WineByGlassData = {};
  
      for (const style in data) {
        sortedData[style] = [];
  
        for (const subStyleData of data[style]) {
          const subStyle = Object.keys(subStyleData)[0];
          const wines: Wine[] = subStyleData[subStyle];
          const sortedWines = wines.slice().sort((a, b) => (a.msrp || 0) - (b.msrp || 0));
  
          sortedData[style].push({ [subStyle]: sortedWines });
        }
      }
  
      sortedWineData.push(sortedData);
    }
  
    return sortedWineData;
  }


  export function reorderWineByGlassData(wineData: WineByGlassData[]): WineByGlassData[] {
    const reorderWineData: WineByGlassData[] = [];
  
    for (const data of wineData) {
      const reorderedData: WineByGlassData = {};
  
      for (const style in data) {
      if (style === "Spirits") {
        const subStyleOrder = ["Shōchū", "Whisky", "Brandy", "Others"];
        for (const subStyle of subStyleOrder) {
            const subStyleData = data[style].find((data) => data.hasOwnProperty(subStyle));
            if (subStyleData) {
                reorderedData[style] = reorderedData[style] || [];
                reorderedData[style].push(subStyleData);
            }
        }
        }
      else if (style === "Fortified Wines"){
            const subStyleOrder = ["Sherry Wines", "Madeira Wines", "Others"];
            for (const subStyle of subStyleOrder) {
                const subStyleData = data[style].find((data) => data.hasOwnProperty(subStyle));
                if (subStyleData) {
                    reorderedData[style] = reorderedData[style] || [];
                    reorderedData[style].push(subStyleData);
                }
            }
        }
        else {
            reorderedData[style] = data[style];
        }
      }
      reorderWineData.push(reorderedData);
    }
    return reorderWineData;
  }   
  
  
  export function groupWineByGlassIntoPages(data: WineByGlassData[]): WineByGlassData[][] {
    const pages: WineByGlassData[][] = [];
    let index = 0;
    let currentWeight = 0;
  
    const wineOrder = [
      "Champagne",
      "White",
      "Red",
      "Sake",
      "Sweet",
      "Sparkling",
      "Fortified Wines",
      "Spirits",
    ];
  
    for (const style of wineOrder) {
      const styleData = data.find((wineData) => wineData.hasOwnProperty(style));
      if (styleData) {
        if (!pages[index]) {
          pages[index] = [];
        }
  
        if (!styleData[style]) {
          continue;
        }
  
        if (currentWeight + countryMinWeight > maxWeight) {
          index++;
          currentWeight = 0;
          pages[index] = [];
        }
  
        pages[index].push({ [style]: [] });
        currentWeight += countryWeight;

        for (const subStyleData of styleData[style]) {
          for (const subStyleTitle in subStyleData) {

            
            if (currentWeight + appellationMinWeight > maxWeight) {
              index++;
              currentWeight = 0;
              pages[index] = [{ [style]: [ { [subStyleTitle] : [] } ] }];
              currentWeight += countryWeight;
            } else{
                if (!pages[index].find((data) => data.hasOwnProperty(subStyleTitle))) {
                    pages[index][pages[index].length - 1][style].push({ [subStyleTitle]: [] });
                    if(subStyleTitle !== 'non-sub-style') {
                        currentWeight += appellationWeight;
                    }
                }

            }


            for (const wine of subStyleData[subStyleTitle]) {
              if (currentWeight + wineWeight > maxWeight) {
                index++;
                currentWeight = 0;
                pages[index] = [{ [style]: [ { [subStyleTitle] : [] } ] }];
                currentWeight += countryWeight;
  
                if (subStyleTitle !== 'non-sub-style') {
                  currentWeight += appellationWeight;
                }
              }
              
              pages[index][pages[index].length - 1][style].find((data) => data.hasOwnProperty(subStyleTitle))![subStyleTitle].push(wine)
              currentWeight += wineWeight;
            }
          }
        }
      }
    }
    return pages;
  }
  
//bubbles
export function categorizeBubbles(wines: Wine[]): BubblesData {
    const bubbleData: any = {
      champagneNV: [],
      champagneRoseNV: [],
      champagne: [],
      champagneRose: [],
      sparkling: []
    };
  
    wines.forEach((wine) => {
      const { specification, appellation, vintage } = wine;
  
      if (appellation && appellation.toLowerCase().includes('champagne')) {
        if (vintage && vintage.includes('NV')) {
          if (specification && specification.toLowerCase().includes('rosé') || specification.toLowerCase().includes('rose')) {
            bubbleData.champagneRoseNV.push(wine);
          } else {
            bubbleData.champagneNV.push(wine); 
          }
        } else {
          if (specification && specification.toLowerCase().includes('rosé') || specification.toLowerCase().includes('rose')) {
            bubbleData.champagneRose.push(wine); 
          } else {
            bubbleData.champagne.push(wine); 
          }
        }
      } else {
        bubbleData.sparkling.push(wine);
      }
    });
  
    Object.keys(bubbleData).forEach((key) => {
      if (bubbleData[key].length === 0) {
        delete bubbleData[key];
      }
    });
  
    return bubbleData;
  }
  

export function sortBubbleFortifiedByMsrp(bubbleData: BubblesData): BubblesData {
const sortedBubbleData: BubblesData = {};

for (const categoryKey in bubbleData) {
    if (Object.prototype.hasOwnProperty.call(bubbleData, categoryKey)) {
    const categoryWines: Wine[] = bubbleData[categoryKey];
    sortedBubbleData[categoryKey] = [...categoryWines].sort((a, b) => a.msrp - b.msrp);
    }
}

return sortedBubbleData;
}

export function groupBubbleFortifiedIntoPages(bubbleData: BubblesData) {
const pages: BubblesData[] = [];
let index = 0;
let currentWeight = 0;
for (const categoryKey in bubbleData) {
    const bubbleStyle = bubbleData[categoryKey];

    if(!pages[index]){
        pages[index] = {}
    }

    if(!pages[index].hasOwnProperty(categoryKey)){
        if(currentWeight + countryMinWeight > maxWeight){
            index++;
            currentWeight = 0;
            pages[index] = {}
        }
        pages[index][categoryKey] = [];
        currentWeight += countryWeight;
    }

    for (const wine of bubbleStyle) {
        if(currentWeight + wineDetailWeight > sparklingMaxWeight){
            index++;
            currentWeight = 0;
            pages[index] = {}
            pages[index][categoryKey] = []
            currentWeight += countryWeight;
        }
        pages[index][categoryKey].push(wine);
        currentWeight += categoryKey === 'sparkling' ? wineDetailWeight : wineWeight;
    }
}
return pages
}


// Red and White Wine
export function categorizeWines(wines: Wine[]): WineData {
const wineData: WineData = {};

wines.forEach((wine) => {
const { style, country, appellation, sub_appellation } = wine;

if (!wineData[style]) {
    wineData[style] = {};
}

const styleData = wineData[style];

if (!styleData[country]) {
    styleData[country] = {};
}

const continentData = styleData[country];

if (appellation && sub_appellation) {
    if (!continentData[appellation]) {
    continentData[appellation] = {};
    }

    const appellationData = continentData[appellation];

    if (!appellationData[sub_appellation]) {
    appellationData[sub_appellation] = [];
    }

    appellationData[sub_appellation]?.push(wine);
} else if (appellation && !sub_appellation){
    if(!continentData[appellation]){
        continentData[appellation] = {};
    }
    
    const appellationData= continentData[appellation];

    if(!appellationData['non-sub-appellation']){
        appellationData['non-sub-appellation'] = [];
    }

    appellationData['non-sub-appellation']?.push(wine);
}

else {
    if (!continentData['non-appellation']) {
    continentData['non-appellation'] = {
        'non-sub-appellation': [],
    };
    }

    const nonAppellationData = continentData['non-appellation'];

    nonAppellationData['non-sub-appellation']?.push(wine);
}
});

return wineData;
}

export function sortWinesByMsrp(wineData: WineData): WineData {
const sortedWineData: WineData = {};

for (const style in wineData) {
sortedWineData[style] = {};

for (const country in wineData[style]) {
    sortedWineData[style][country] = {};

    for (const appellation in wineData[style][country]) {
    const subAppellations = wineData[style][country][appellation];

    if (subAppellations) {
        sortedWineData[style][country][appellation] = {};

        for (const subAppellation in subAppellations) {
        const wines = subAppellations[subAppellation];

        if (wines) {
            const sortedWines = [...wines].sort((a, b) => +a.msrp - +b.msrp);
            sortedWineData[style][country][appellation][subAppellation] = sortedWines;
        }
        }
    } else {
        const nonSubAppellations = wineData[style][country]['non-appellation'];

        if (nonSubAppellations) {
        const wines = nonSubAppellations['non-sub-appellation'];

        if (wines) {
            const sortedWines = [...wines].sort((a, b) => +a.msrp - +b.msrp);
            sortedWineData[style][country]['non-appellation']['non-sub-appellation'] = sortedWines;
        }
        }
    }
    }
}
}

return sortedWineData;
}

export function checkTotalWeight(WineAppellationData: WineAppellationData): number {
let totalWeight = countryWeight;
for (const appellation in WineAppellationData) {
    totalWeight += appellation === "non-appellation" ? 0 : appellationWeight;
    for (const subAppellation in WineAppellationData[appellation]) {
        totalWeight += subAppellation === "non-sub-appellation" ? 0 : subAppellationWeight;
        totalWeight += WineAppellationData[appellation][subAppellation]!.length * wineWeight; 
    }
}
return totalWeight;
}


export async function groupDataIntoPages(data: WineData, style: string): Promise<WineData[]> {
  const initWineData = {
      [style]: {}
  }
  const wineData: WineData[] = [initWineData];
  let index = 0;
  let currentWeight = 0;
  
  if (data.hasOwnProperty(style as keyof typeof data)) {
      const styleData = data[style as keyof typeof data];
  
      let dataOrder;
      let countryOrder;
      try {
          const res = await axiosCredentialsInstance.get(`/appellation/${style}`);
          dataOrder = res.data;
          
          const res2 = await axiosCredentialsInstance.get(`/appellation/country-order/${style}`);
          countryOrder = res2.data.countries;
  
      } catch (error) {}
  
      for (const country of countryOrder) {
          const countryData = styleData[country];
          if (countryData === undefined) { 
              continue
          }
              if (!wineData[index].hasOwnProperty(country)) {
                  if (currentWeight + countryMinWeight >= maxWeight) {
                      index++;
                      currentWeight = 0;
                      wineData.push({
                          [style]: {
                              [country]: {}
                          }
                      });
                  }
                  wineData[index][style][country] = {};
                  currentWeight += countryWeight;
              }
  
              const appellationOrder = dataOrder.countries.find((item: any) => item.country === country)?.appellations;
              for (const appellation of appellationOrder) {
                  const appellationData = countryData[appellation.appellation];
                  if (appellationData === undefined) { 
                      continue 
                  }
                  if (!wineData[index][style][country].hasOwnProperty(appellation.appellation) && appellation.appellation !== 'non-appellation') {
                      if (currentWeight + appellationMinWeight >= maxWeight) {
                          index++;
                          currentWeight = countryWeight;
                          wineData.push({
                              [style]: {
                                  [country]: {
                                      [appellation.appellation]: {}
                                  }
                              }
                          });
                      }
                      wineData[index][style][country][appellation.appellation] = {};
                      currentWeight += appellationWeight;
                  } else if (appellation.appellation === 'non-appellation') {
                      wineData[index][style][country][appellation.appellation] = {};
                  }
  
                  for (const subAppellation of appellation.subAppellations) {
                      const subAppellationData = appellationData[subAppellation];
                      if (subAppellationData === undefined) { 
                          continue
                      }
                      if (!wineData[index][style][country][appellation.appellation].hasOwnProperty(subAppellation) && subAppellation !== 'non-sub-appellation') {
  
                          if (currentWeight + subAppellationMinWeight > maxWeight) {
                              index++;
                              currentWeight = 0;
                              wineData.push({
                                  [style]: {
                                      [country]: {
                                          [appellation.appellation]: {
                                              [subAppellation]: []
                                          }
                                      }
                                  }
                              });
                              currentWeight += countryWeight;
                              currentWeight += appellation.appellation === 'non-appellation' ? 0 : appellationWeight;
                          }
                          wineData[index][style][country][appellation.appellation][subAppellation] = [];
                          currentWeight += subAppellationWeight;
                      } else if (subAppellation === 'non-sub-appellation') {
                          wineData[index][style][country][appellation.appellation][subAppellation] = [];
                      }
  
                      for (const wine of subAppellationData!) {
                          if (currentWeight + wineWeight > maxWeight) {
                              index++;
                              currentWeight = 0;
                              wineData.push({
                                  [style]: {
                                      [country]: {
                                          [appellation.appellation]: {
                                              [subAppellation]: []
                                          }
                                      }
                                  }
                              });
                              currentWeight += countryWeight;
                              currentWeight += appellation.appellation === 'non-appellation' ? 0 : appellationWeight;
                              currentWeight += subAppellation === 'non-sub-appellation' ? 0 : subAppellationWeight;
                          }
                          wineData[index][style][country][appellation.appellation][subAppellation]!.push(wine);
                          currentWeight += checkOverflow(wine.specification, 555.77) ? wineOverFlowWeight : 0;
                          currentWeight += wineWeight;
                      }
                  }
              }
      }
  
  }
  
  return wineData;
  }
  

export function sortSweetByMsrp(wines: Wine[]) : Wine[] {
return wines.sort((a, b) => {
    return a.msrp - b.msrp;
});
}

export function sortSommeliers(wines: Wine[]): Wine[] {
  const styleOrder: { [key: string]: number } = { "Bubbles": 1, "White": 2, "Red": 3 };

  wines.sort((wineA, wineB) => {
    // Compare by style
    const styleComparison = styleOrder[wineA.style] - styleOrder[wineB.style];

    // If styles are the same, compare by MSRP
    if (styleComparison === 0) {
      return wineA.msrp - wineB.msrp;
    } else {
      return styleComparison;
    }
  });

  return wines;
}

export function groupSweetIntoPages (wines: Wine[]) {
const pages: Wine[][] = [[]];

let index = 0;
let currentWeight = 0;

for (const wine of wines) {
    if (currentWeight + wineWeight * 2 > maxWeight) {
        index++;
        currentWeight = 0;
        pages[index] = [];
    }
    pages[index].push(wine);
    currentWeight += wineDetailWeight;
}
if (pages[0].length === 0) {
  pages.shift();
}
return pages
}

export function groupSommelierIntoPages (wines: Wine[]) {
  const pages: Wine[][] = [[]];
  let index = 0;
  let currentWeight = 0;

  for (const wine of wines) {
      if (currentWeight + wineWeight * 2 > maxWeight) {
          index++;
          currentWeight = 0;
          pages[index] = [];
      }
      pages[index].push(wine);
      currentWeight += wineDetailWeight;
  }
  return pages
}