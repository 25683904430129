import { useEffect, useState } from 'react';
import WineByGlassPage from '../customer/WineByGlassPage';
import WinePage from '../customer/WinePage';
import WineBubblePage from '../customer/WineBubblePage';
import WineSingleListingTemplate from '../customer/WineSingleListingTemplate';
import WineSweetPage from '../customer/WineSweetPage';
import WineFortifiedPage from '../customer/WineFortifiedPage';
import WineSommeliersPage from '../customer/WineSommeliersPage';
import WineSpiritPage from '../customer/WineSpiritPage';
import WineIndexPage from '../customer/WineIndexPage';
import jsPDF from 'jspdf';
import { BubblesData, Wine, WineData, WineByGlassData } from '../interfaces';
import { categorizeWinesByGlassStyle, sortWinesByGlassByMsrp, reorderWineByGlassData, groupWineByGlassIntoPages, categorizeBubbles, sortBubbleFortifiedByMsrp, groupBubbleFortifiedIntoPages, categorizeWines, sortWinesByMsrp, groupDataIntoPages, sortSweetByMsrp, groupSweetIntoPages, categorizeFortified, sortSommeliers, groupSommelierIntoPages } from '../static/wineClassifier'
import { splitIntoChunks, rearrangeWineKeys, transformAppellationData, combineAdjacentWhiteData, processWineData, transformRedList, invertKeyValue } from '../static/PageIndexClassifier';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import callAddFont from '../static/msjh-normal';
import callAddConceptFont from '../static/Concept Bold-normal';
jsPDF.API.events.push(['addFonts', callAddFont]);
jsPDF.API.events.push(['addFonts', callAddConceptFont]);

function WineCustomerDashboard(props: any) {
    const [wineIndexDataPages, setWineIndexDataPages] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState<any>({});
    const [wineByGlassRestaurant, setWineByGlassRestaurant] = useState<string | null>(
        props.restaurant === "frenchie-frenchie"
            ? "wine_by_glass_ff"
            : props.restaurant === "la-maison-de-win"
                ? "wine_by_glass_win"
                : null
    );

    const [RedWineDataPages, setRedWineDataPages] = useState<WineData[]>([]);
    const [WhiteWineDataPages, setWhiteWineDataPages] = useState<WineData[]>([]);
    const [wineByGlassDataPages, setWineByGlassDataPages] = useState<WineByGlassData[][]>([]);
    const [bubblesDataPages, setBubblesDataPages] = useState<BubblesData[]>([]);
    const [fortifiedDataPages, setfortifiedDataPages] = useState<BubblesData[]>([]);
    const [roseWineDataPages, setRoseWineDataPages] = useState<Wine[][]>([[]]);
    const [wineSweetPages, setWineSweetPages] = useState<Wine[][]>([[]]);
    const [wineSommeliersPages, setWineSommeliersPages] = useState<Wine[][]>([[]]);
    const [wineSpiritPages, setWineSpiritPages] = useState<WineByGlassData[][]>([]);
    const [wineBeerPages, setWineBeerPages] = useState<Wine[][]>([]);
    const [wineNonAlcoholicPages, setWineNonAlcoholicPages] = useState<Wine[][]>([]);

    useEffect(() => {
        const fetchWines = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false ,style eq Red , style eq White, wine_by_glass_ff eq false, wine_by_glass_win eq false');
                const wines = res.data;

                const wineData: WineData = categorizeWines(wines);

                const sortedWineData: WineData = sortWinesByMsrp(wineData);

                const whiteDataPages = await groupDataIntoPages(sortedWineData, "White");

                setWhiteWineDataPages(whiteDataPages);

                const redDataPages = await groupDataIntoPages(sortedWineData, "Red");

                setRedWineDataPages(redDataPages);

            } catch (error) {
            }
        };

        const fetchBubbles = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Bubbles, wine_by_glass_ff eq false, wine_by_glass_win eq false');

                const bubbleData = categorizeBubbles(res.data);

                const sortedBubbleData = sortBubbleFortifiedByMsrp(bubbleData);

                const bubblePages = groupBubbleFortifiedIntoPages(sortedBubbleData);

                setBubblesDataPages(bubblePages)

            } catch (error) {
                console.log(error);
            }
        }

        const fetchSweets = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Sweet, wine_by_glass_ff eq false, wine_by_glass_win eq false');

                const sortedSweetData = sortSweetByMsrp(res.data);

                const sweetPages = groupSweetIntoPages(sortedSweetData);

                setWineSweetPages(sweetPages);
            } catch (error) {
                console.log(error);
            }
        }

        const fetchRose = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Rose, wine_by_glass_ff eq false, wine_by_glass_win eq false');

                const sortedRoseData = sortSweetByMsrp(res.data);

                const rosePages = groupSweetIntoPages(sortedRoseData);

                setRoseWineDataPages(rosePages);
            } catch (error) {
                console.log(error);
            }
        }

        const fetchFortifieds = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, wine_by_glass_ff eq false, wine_by_glass_win eq false &$search=fortified wines');

                const categorizedFortifiedData = categorizeFortified(res.data);

                const sortedFortifiedData = sortBubbleFortifiedByMsrp(categorizedFortifiedData);

                const fortifiedPages = groupBubbleFortifiedIntoPages(sortedFortifiedData);

                setfortifiedDataPages(fortifiedPages);
            } catch (error) {
                console.log(error);
            }
        }

        const fetchSpirits = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Spirits, wine_by_glass_ff eq false, wine_by_glass_win eq false');

                const wineData = categorizeWinesByGlassStyle(res.data);

                const sortedWineData = sortWinesByGlassByMsrp(wineData);

                const reorderedWineData = reorderWineByGlassData(sortedWineData);

                const wineSpiritPages = groupWineByGlassIntoPages(reorderedWineData)

                setWineSpiritPages(wineSpiritPages);

            } catch (error) {
                console.log(error);
            }
        }

        const fetchBeer = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Beer');

                const sortedBeerData = sortSweetByMsrp(res.data);

                const beerPages = groupSweetIntoPages(sortedBeerData);

                setWineBeerPages(beerPages);
            }
            catch (error) {
                console.log(error);
            }
        }

        const fetchNonAlcoholic = async () => {
            try {
                const res = await axiosCredentialsInstance.get("/wine?$filter=archived eq false, style eq 'Non Alcoholic'");

                const sortedNonAlcoholicData = sortSweetByMsrp(res.data);
    
                const nonAlcoholicPages = groupSweetIntoPages(sortedNonAlcoholicData);

                setWineNonAlcoholicPages(nonAlcoholicPages);
            }
            catch (error) {
                console.log(error);
            }
        }

        fetchSweets();
        fetchBubbles();
        fetchWines();
        fetchRose();
        fetchFortifieds();
        fetchSpirits();
        fetchBeer();
        fetchNonAlcoholic();
    }, []);

    useEffect(() => {
        setWineIndexDataPages(splitIntoChunks(processWineData(processWineData(invertKeyValue(pageIndex), "White"), "Red"), 27))
    }, [pageIndex])

    useEffect(() => {
        const fetchWineByGlass = async () => {
            try {

                const res = await axiosCredentialsInstance.get(`/wine?$filter=archived eq false, ${wineByGlassRestaurant} eq true`);

                const wineData = categorizeWinesByGlassStyle(res.data);

                const sortedWineData = sortWinesByGlassByMsrp(wineData);

                const reorderedWineData = reorderWineByGlassData(sortedWineData);

                const wineByGlassPages = groupWineByGlassIntoPages(reorderedWineData)

                setWineByGlassDataPages(wineByGlassPages);

            } catch (error) {
                console.log(error);
            }
        };
        fetchWineByGlass()
    }, [wineByGlassRestaurant])

    useEffect(() => {
        const fetchSommeliers = async () => {
            try {
                const res = await axiosCredentialsInstance.get(`/wine?$filter=archived eq false, sommeliers eq true`);

                const wineData = sortSommeliers(res.data);

                const sommeliersPages = groupSommelierIntoPages(wineData);

                setWineSommeliersPages(sommeliersPages);

            } catch (error) {
                console.log(error);
            }
        };
        fetchSommeliers()
    }, [])

    var currentIndex = 0;

    return (
        <div>
            <div className='w-full flex flex-wrap justify-center bg-slate-50'>

                <div className='w-full'>
                    {wineIndexDataPages.map((wineIndexPage: any, index: number) => {
                            return (
                                <WineIndexPage wineIndex={wineIndexPage}/>
                            )
                        })
                    }
                    {
                        wineByGlassDataPages.map((wineByGlassDataPage, index) => {
                            currentIndex++;
                            return (
                                <WineByGlassPage wineByGlassDataPage={wineByGlassDataPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        wineSommeliersPages.map((wineSommeliersPage, index) => {
                            currentIndex++;
                            return (
                                <WineSommeliersPage wineSommeliersPage={wineSommeliersPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        bubblesDataPages.map((bubblesDataPage, index) => {
                            currentIndex++;
                            return (
                                <WineBubblePage bubblesDataPage={bubblesDataPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }

                    {
                        WhiteWineDataPages.map((WhiteWineDataPage, index) => {
                            currentIndex++;
                            return (
                                <WinePage wineData={WhiteWineDataPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }

                    {
                        roseWineDataPages.map((roseWineDataPage, index) => {
                            currentIndex++;
                            return (
                                <WineSingleListingTemplate wineSweetPage={roseWineDataPage} key={index} title={"Rosé Wines"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }

                    {
                        RedWineDataPages.map((RedWineDataPage, index) => {
                            currentIndex++;
                            return (
                                <WinePage wineData={RedWineDataPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        wineNonAlcoholicPages.map((wineNonAlcoholicPage, index) => {
                            currentIndex++;
                            return (
                                <WineSingleListingTemplate wineSweetPage={wineNonAlcoholicPage} key={index} title={"Non Alcoholic"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        wineBeerPages.map((wineBeerPage, index) => {
                            currentIndex++;
                            return (
                                <WineSingleListingTemplate wineSweetPage={wineBeerPage} key={index} title={"Beer"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        wineSweetPages.map((wineSweetPage, index) => {
                            currentIndex++;
                            return (
                                <WineSweetPage wineSweetPage={wineSweetPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        }
                        )}

                    {
                        fortifiedDataPages.map((bubblesDataPage, index) => {
                            currentIndex++;
                            return (
                                <WineFortifiedPage bubblesDataPage={bubblesDataPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }
                    {
                        wineSpiritPages.map((wineSpiritPage, index) => {
                            currentIndex++;
                            return (
                                <WineSpiritPage wineSpiritPage={wineSpiritPage} key={index} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                            )
                        })
                    }

                </div>


            </div>
        </div>
    );
};

export default WineCustomerDashboard;
