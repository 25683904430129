export const backgroundColor = "rgba(251,247,239,0.7)"

export function stripStyleName(style: string): string {
  const pattern = /^(?:\d{4}|nv)\s+/i;

  const strippedName = style.replace(pattern, "");

  return strippedName;
}

export function getTextWidth(text: string, fontSize: number, fontFamily: string): number {

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context!.font = `${fontSize}px ${fontFamily}`;

  const width = context!.measureText(text).width;

  canvas.remove();

  return width;
}

export function checkOverflow(text: string, overflowWidth: number): boolean {
  return getTextWidth(stripStyleName(text), 14, "Arial") > overflowWidth ? true : false;
}

export function containsChineseCharacters(str: string): boolean {
  const chineseRegex = /[\u4E00-\u9FFF\u3400-\u4DBF\u{20000}-\u{2FA1F}]/u;
  return chineseRegex.test(str);
}

export const styleMap: Record<string, string> = {
  "Red": "Red Wines",
  "White": "White Wines",
  "Rose": "Rosé",
  "Sherry": "Sherry",
  "Sweet": "Sweet Wines",
  "Spirits": "Spirits",
  "Sparkling": "Sparkling",
  "Sake": "Sake",
  "Beer": "Beer",
  "Non Alcoholic": "Non Alcoholic",
  "Fortified": "Fortified Wines",
  "Fortified Wines": "Fortified Wines",
  "Champagne": "Champagne",
  "champagne": "VINTAGE  CHAMPAGE",
  "champagneRose": "ROSÉ  VINTAGE  CHAMPAGE",
  "champagneNV": "NON - VINTAGE  CHAMPAGNE",
  "champagneRoseNV": "ROSÉ NON - VINTAGE  CHAMPAGNE",
  "sparkling": "SPARKLING",
  "sherry": "Sherry Wines",
  "madeira": "Madeira Wines",
  "others": "Others"

};

export const wineTranslationMap: Record<string, string> = {
  "code": "Code",
  "vintage": "Vintage",
  "style": "Style",
  "specification": "品名規格",
  "msrp": "建議售價",
  "country": "國家",
  "ml": "容量",
  "inventory": "庫存",
  "appellation": "產區",
  "sub_appellation": "小產區",
  "natural": "Natural",
  "wine_by_glass_ff": "FF單點",
  "wine_by_glass_win": "WIN單點",
  "archived": "Archived",
  'sommeliers': "Sommeliers"
}

export const mlMap: Record<string, string> = {
  "Champagne": "150 ml",
  "Sparkling": "150 ml",
  "White": "150 ml",
  "Red": "150 ml",
  "Sake": "150 ml",
  "Sweet": "60 ml",
  "Fortified Wines": "30 ml",
  "Spirits": "30 ml"
}


export const wineStyles = [
  'Red',
  'White',
  'Rose',
  'Sweet',
  'Liquor',
  'Sake',
  'Bubbles',
  'Fortified Wines',
  'Spirits',
  'Beer',
  'Non Alcoholic',
]

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "England",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji Islands",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "North Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Ireland",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Scotland",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "The Democratic Republic of Congo",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wales",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]