import "../App.css";



function WineIndexPage(props: any) {
    function ifKeyStartsWithHyphen(key: string): boolean {
        if (key.startsWith("-")) {
            return true;
        }
        return false;
    }


    function removeRedOrWhite(key: string): string {
        if (key.startsWith("Red") && key !== "Red" || key.startsWith("White") && key !== "White") {
            return "- " + key.substring(key.indexOf(" ") + 1).trim();
        }

        return key.charAt(0).toUpperCase() + key.slice(1);
    }
    
    return (
        <div className={`flex justify-center mb-10 `} style={{ fontFamily: 'Arial', minHeight: '60rem' }} >
            <div className="flex flex-col shadow-lg w-md sm:w-md md:w-lg lg:w-xl px-4 sm:px-6 md:px-4 lg:px-6 xl:px-8 bg-white pb-8">
                <div className="flex justify-center mb-2">
                    <div className="flex items-center justify-center relative w-72 md:w-80" style={{ height: "5rem", backgroundColor: 'rgb(86, 67, 60)' }}>
                        <div className="absolute" style={{ left: "-1.5rem", top: "4.5rem" }}>
                            <div className="w-12 h-12 rounded-full bg-white"></div>
                        </div>
                        <h1 className={`font-bold text-white text-center`} style={{ fontSize: '2.4rem', fontFamily: '"Garamond", serif' }}>
                            Wine List
                        </h1>
                        <div className="absolute" style={{ right: "-1.5rem", top: "4.5rem" }}>
                            <div className="w-12 h-12 rounded-full bg-white"></div>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto flex justify-center mt-8" style={{ width: "35rem" }}>
                <table className="min-w-full bg-white border-gray-200" style={{ width: "30rem" }}>
                    <thead>
                    <tr>
                        <th className="py-2 px-2 border-b-2 border-r-2 w-28 text-right text-gray-400 pr-6">Page</th>
                        <th className="py-2 px-2 border-b-2 text-gray-400 text-left pl-4">Table of Content</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.wineIndex.map((entry: any, index: number) => {
                        var key = Object.keys(entry)[0];
                        const value = entry[key];
                        key = removeRedOrWhite(key);
                        return (
                        <tr key={index} className="text-gray-700">
                            <td className="py-2 text-right pr-8 text-md border-r-2 font-bold">{value}</td>
                            {!ifKeyStartsWithHyphen(key) ?
                             <td className={`py-2 px-4 text-lg font-bold`}>
                                <span className="text-2xl">{key.slice(0,1)}</span>
                                {key.slice(1)}</td> :
                            <td className={`py-2 px-4 text-md`}><span className="pl-2"></span>{key}</td>}
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    );
}

export default WineIndexPage;
