import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from "react-dnd";
import { SubCardProps, DragItem } from '../interfaces'
import axiosCredentialsInstance from '../Axios/axiosCredentials'
import { PiDotsSixVerticalBold } from 'react-icons/pi'

function debounce<T extends (...args: any[]) => any>(callback: T, delay: number): T {
  let timerId: ReturnType<typeof setTimeout> | null;

  return function (this: any, ...args: Parameters<T>) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      callback.apply(this, args);
    }, delay) as any;
  } as T;
}

const debouncedUpdateDrop = debounce(async (dragStartIndex: number, dropEndIndex: number, style: string, country: string, appellation: string) => {
  console.log('executed')
  axiosCredentialsInstance.get(`/appellation/swap/${style}/${country}/${appellation}?dragIndex=${dragFirstIndex}&dropIndex=${dropEndIndex}`);
  dragFirstIndex = null
}, 500);

let dragFirstIndex: null | number = null


export const SubCard: FC<SubCardProps> = ({ id, text, index, appellationType, style, country, appellation, moveSubCard }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
    >({
      accept: appellationType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item: DragItem, monitor) {

        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // console.log(dragIndex, hoverIndex)
  
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
  
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // console.log(clientOffset)
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
  
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
  
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
  
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        if(dragFirstIndex === null){
          dragFirstIndex = dragIndex
        }
  
        updateDrop(dragIndex, hoverIndex,)
        moveSubCard(dragIndex, hoverIndex)
  
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })

    const updateDrop = (dragStartIndex: number, dropEndIndex: number) => {
      debouncedUpdateDrop(dragStartIndex, dropEndIndex, style, country, appellation);
    };
  
    const [{ isDragging }, drag] = useDrag({
      type: appellationType,
      item: () => {
        return { id, index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
      <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className='flex border-solid border-gray-500 border-2 my-2 rounded-lg'>
          <div className='h-full my-auto relative'><PiDotsSixVerticalBold size={20}/></div>
          <span className='mx-auto'>
          {text === "non-sub-appellation" ? "無小產區" : text}
          </span>
          <div style={{ visibility: 'hidden', width: "20px" }}></div>
      </div>
    )
}