import { useEffect, useState, useRef } from 'react';
import WineIndexPage from '../components/WineIndexPage';
import WineByGlassPage from '../components/WineByGlassPage';
import WinePage from '../components/WinePage';
import WineBubblePage from '../components/WineBubblePage';
import WineSingleListingTemplatePage from '../components/WineSingleListingTemplatePage';
import WineSweetPage from '../components/WineSweetPage';
import WineFortifiedPage from '../components/WineFortifiedPage';
import WineSommeliersPage from '../components/WineSommeliersPage';
import WineSpiritPage from '../components/WineSpiritPage';
import { RiPrinterCloudLine } from 'react-icons/ri';
import jsPDF from 'jspdf';
import { BubblesData, Wine, WineData, WineByGlassData } from '../interfaces';
import { categorizeWinesByGlassStyle, sortWinesByGlassByMsrp, reorderWineByGlassData, groupWineByGlassIntoPages, categorizeBubbles, sortBubbleFortifiedByMsrp, groupBubbleFortifiedIntoPages, categorizeWines, sortWinesByMsrp, groupDataIntoPages, sortSweetByMsrp, groupSweetIntoPages, categorizeFortified, sortSommeliers, groupSommelierIntoPages } from '../static/wineClassifier'
import { splitIntoChunks, rearrangeWineKeys, transformAppellationData, combineAdjacentWhiteData, processWineData, transformRedList, invertKeyValue } from '../static/PageIndexClassifier';
import axiosCredentialsInstance from '../Axios/axiosCredentials';
import callAddFont from '../static/msjh-normal';
import callAddConceptFont from '../static/Concept Bold-normal';
import { parse } from 'path';
jsPDF.API.events.push(['addFonts', callAddFont]);
jsPDF.API.events.push(['addFonts', callAddConceptFont]);

function WineDashboard() {
    const [wineIndexDataPages, setWineIndexDataPages] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState<any>({});
    const [wineByGlassRestaurant, setWineByGlassRestaurant] = useState<string>('wine_by_glass_ff');
    const [wineRestaurant, setWineRestaurant] = useState<string>('FReNCHIE');
    const [RedWineDataPages, setRedWineDataPages] = useState<WineData[]>([]);
    const [WhiteWineDataPages, setWhiteWineDataPages] = useState<WineData[]>([]);
    const [wineByGlassDataPages, setWineByGlassDataPages] = useState<WineByGlassData[][]>([]);
    const [bubblesDataPages, setBubblesDataPages] = useState<BubblesData[]>([]);
    const [fortifiedDataPages, setfortifiedDataPages] = useState<BubblesData[]>([]);
    const [roseWineDataPages, setRoseWineDataPages] = useState<Wine[][]>([[]]);
    const [wineSweetPages, setWineSweetPages] = useState<Wine[][]>([[]]);
    const [wineSommeliersPages, setWineSommeliersPages] = useState<Wine[][]>([[]]);
    const [wineSpiritPages, setWineSpiritPages] = useState<WineByGlassData[][]>([[]]);
    const [wineBeerPages, setWineBeerPages] = useState<Wine[][]>([[]]);
    const [wineNonAlcoholicPages, setWineNonAlcoholicPages] = useState<Wine[][]>([[]]);

    const [converting, setConverting] = useState<boolean>(false);
    const reportTemplateRef = useRef<HTMLDivElement>(null);
    function getCurrentDateFormatted(): string {
        const currentDate = new Date();
        const year = currentDate.getFullYear().toString();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
    }

    const handleGeneratePdf = async () => {
        setConverting(true);

        const doc = new jsPDF({
            format: [17.01, 28.5],
            unit: 'cm',
        });

        doc.setFont('msjh', 'normal');
        doc.setFont('Concept Bold', 'normal')
        const reportTemplate = reportTemplateRef.current;

        if (reportTemplate) {
            const options = {
                callback: (doc: any) => {
                    doc.save(`${wineRestaurant}_${getCurrentDateFormatted()}`);
                },
            };

            const contentWidth = reportTemplate.offsetWidth;
            const pdfWidth = doc.internal.pageSize.getWidth();
            const scale = pdfWidth / contentWidth;


            await doc.html(reportTemplate, {
                ...options,
                x: 0,
                y: 0,
                html2canvas: { scale },
            });

            setConverting(false);
        }
    };
    useEffect(() => {
        const fetchWines = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false ,style eq Red , style eq White, wine_by_glass_ff eq false, wine_by_glass_win eq false');
                const wines = res.data;
    
                const wineData = categorizeWines(wines);
    
                const sortedWineData = sortWinesByMsrp(wineData);
    
                const whiteDataPages = await groupDataIntoPages(sortedWineData, "White");


                setWhiteWineDataPages(whiteDataPages);
    
                const redDataPages = await groupDataIntoPages(sortedWineData, "Red");
    
                setRedWineDataPages(redDataPages);
            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchBubbles = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Bubbles, wine_by_glass_ff eq false, wine_by_glass_win eq false');
    
                const bubbleData = categorizeBubbles(res.data);
    
                const sortedBubbleData = sortBubbleFortifiedByMsrp(bubbleData);
    
                const bubblePages = groupBubbleFortifiedIntoPages(sortedBubbleData);
    
                setBubblesDataPages(bubblePages);

            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchSweets = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Sweet, wine_by_glass_ff eq false, wine_by_glass_win eq false');
    
                const sortedSweetData = sortSweetByMsrp(res.data);
    
                const sweetPages = groupSweetIntoPages(sortedSweetData);
    
                setWineSweetPages(sweetPages);
            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchRose = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Rose, wine_by_glass_ff eq false, wine_by_glass_win eq false');
    
                const sortedRoseData = sortSweetByMsrp(res.data);
    
                const rosePages = groupSweetIntoPages(sortedRoseData);
    
                setRoseWineDataPages(rosePages);
            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchFortifieds = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, wine_by_glass_ff eq false, wine_by_glass_win eq false &$search=fortified wines');
    
                const categorizedFortifiedData = categorizeFortified(res.data);
    
                const sortedFortifiedData = sortBubbleFortifiedByMsrp(categorizedFortifiedData);
    
                const fortifiedPages = groupBubbleFortifiedIntoPages(sortedFortifiedData);
    
                setfortifiedDataPages(fortifiedPages);
            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchSpirits = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Spirits, wine_by_glass_ff eq false, wine_by_glass_win eq false');
    
                const wineData = categorizeWinesByGlassStyle(res.data);
    
                const sortedWineData = sortWinesByGlassByMsrp(wineData);
    
                const reorderedWineData = reorderWineByGlassData(sortedWineData);
    
                const wineSpiritPages = groupWineByGlassIntoPages(reorderedWineData);

                setWineSpiritPages(wineSpiritPages);
            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchSommeliers = async () => {
            try {
                const res = await axiosCredentialsInstance.get(`/wine?$filter=archived eq false, sommeliers eq true`);
    
                const wineData = sortSommeliers(res.data);
    
                const sommeliersPages = groupSommelierIntoPages(wineData);
    
                setWineSommeliersPages(sommeliersPages);

            } catch (error) {
                console.log(error);
            }
        };
    
        const fetchWineByGlass = async () => {
            try {
                const res = await axiosCredentialsInstance.get(`/wine?$filter=archived eq false, ${wineByGlassRestaurant} eq true`);

                const wineData = categorizeWinesByGlassStyle(res.data);
    
                const sortedWineData = sortWinesByGlassByMsrp(wineData);
    
                const reorderedWineData = reorderWineByGlassData(sortedWineData);

                const wineByGlassPages = groupWineByGlassIntoPages(reorderedWineData);
    
                setWineByGlassDataPages(wineByGlassPages);

            } catch (error) {
                console.log(error);
            }
        };

        const fetchBeer = async () => {
            try {
                const res = await axiosCredentialsInstance.get('/wine?$filter=archived eq false, style eq Beer');

                const sortedBeerData = sortSweetByMsrp(res.data);

                const beerPages = groupSweetIntoPages(sortedBeerData);

                setWineBeerPages(beerPages);
            }
            catch (error) {
                console.log(error);
            }
        }

        const fetchNonAlcoholic = async () => {
            try {
                const res = await axiosCredentialsInstance.get("/wine?$filter=archived eq false, style eq 'Non Alcoholic'");

                const sortedNonAlcoholicData = sortSweetByMsrp(res.data);
    
                const nonAlcoholicPages = groupSweetIntoPages(sortedNonAlcoholicData);

                setWineNonAlcoholicPages(nonAlcoholicPages);
            }
            catch (error) {
                console.log(error);
            }
        }

        fetchSweets();
        fetchBubbles();
        fetchWines();
        fetchRose();
        fetchFortifieds();
        fetchSpirits();
        fetchWineByGlass();
        fetchBeer();
        fetchNonAlcoholic();
        fetchSommeliers();
    }, [wineByGlassRestaurant]);


    useEffect(() => {
        setWineIndexDataPages(splitIntoChunks(processWineData(processWineData(invertKeyValue(pageIndex), "White"), "Red"), 27))
    }, [pageIndex])

    var currentIndex = 0;

    return (
        <div>
            <div className='w-full flex flex-wrap justify-center bg-slate-50'>
                <div className='w-260 flex justify-between fixed bottom-5'>
                    <div className="mr-6">
                    </div>

                    <div className=''></div>

                    <div className="mr-6">
                        <div className='flex flex-col bg-gray-100 rounded-lg px-2'>
                            <button className={`py-2 px-4 my-2 rounded-lg shadow-md flex justify-center text-xl align-middle w-36 h-18 ${wineByGlassRestaurant === "wine_by_glass_ff" ? "bg-primary border-2 border-white text-white" : "bg-white text-gray-400 "}`} onClick={() => {
                                setWineByGlassRestaurant('wine_by_glass_ff')
                                setWineRestaurant('FReNCHIE')
                            }}>
                                FReNCHIE
                            </button>
                            <button className={`py-2 px-4 my-2 rounded-lg shadow-md flex justify-center text-xl align-middle w-36 h-18 ${wineByGlassRestaurant === "wine_by_glass_win" ? "bg-primary border-2 border-white text-white" : "bg-white text-gray-400 "}`} onClick={() => {
                                setWineByGlassRestaurant('wine_by_glass_win')
                                setWineRestaurant('文公館')
                            }}>
                                文公館
                            </button>
                        </div>
                        <button className="text-black py-2 px-4 my-4 rounded-lg shadow-md flex justify-center text-xl align-middle w-36 h-18 bg-gray-button-click mx-auto" onClick={handleGeneratePdf}>
                            <div className='my-auto flex text-white'>
                                <div className='w-full h-full mx-auto my-auto mr-2 '>
                                    <RiPrinterCloudLine />
                                </div>
                                Export
                            </div>
                        </button>
                    </div>
                </div>

                <div className={`my-10 ${converting ? "shadow-lg" : ""}`}>
                    <div ref={reportTemplateRef} className='w-190'>
                        {wineIndexDataPages.map((wineIndexPage: any, index: number) => {
                            return (
                                <WineIndexPage wineIndex={wineIndexPage} converting={converting}/>
                            )
                            })
                        }

                        {
                            wineByGlassDataPages.map((wineByGlassDataPage, index: number) => {
                                currentIndex++;
                                return (
                                    <WineByGlassPage wineByGlassDataPage={wineByGlassDataPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            wineSommeliersPages.map((wineSommeliersPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSommeliersPage wineSommeliersPage={wineSommeliersPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            bubblesDataPages.map((bubblesDataPage, index) => {
                                currentIndex++;
                                return (
                                    <WineBubblePage bubblesDataPage={bubblesDataPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            WhiteWineDataPages.map((WhiteWineDataPage, index) => {
                                currentIndex++;
                                return (
                                    <WinePage wineData={WhiteWineDataPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            roseWineDataPages.map((roseWineDataPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSingleListingTemplatePage wineSweetPage={roseWineDataPage} key={index} converting={converting} title={"Rosé Wines"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            RedWineDataPages.map((RedWineDataPage, index) => {
                                currentIndex++;
                                return (
                                    <WinePage wineData={RedWineDataPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            wineNonAlcoholicPages.map((wineNonAlcoholicPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSingleListingTemplatePage wineSweetPage={wineNonAlcoholicPage} key={index} converting={converting} title={"Non Alcoholic"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            }
                            )
                        }
                        {
                            wineBeerPages.map((wineBeerPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSingleListingTemplatePage wineSweetPage={wineBeerPage} key={index} converting={converting} title={"Beer"} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            }
                            )
                        }
                        {
                            wineSweetPages.map((wineSweetPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSweetPage wineSweetPage={wineSweetPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            }
                            )}
                        {
                            fortifiedDataPages.map((bubblesDataPage, index) => {
                                currentIndex++;
                                return (
                                    <WineFortifiedPage bubblesDataPage={bubblesDataPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            })
                        }
                        {
                            wineSpiritPages.map((wineSpiritPage, index) => {
                                currentIndex++;
                                return (
                                    <WineSpiritPage wineSpiritPage={wineSpiritPage} key={index} converting={converting} page={currentIndex} pageIndex={pageIndex} setPageIndex={setPageIndex}/>
                                )
                            }
                            )
                        }
                    </div>
                </div>


            </div>
        </div>
    );
};

export default WineDashboard;
